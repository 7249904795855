import React from "react";
import DevicesTable from "./devices/DevicesTable";
import DevicesMap from "./devices/DevicesMap";
import SearchProvider from "../components/SearchProvider";
import DeviceSearchBar from "./devices/DeviceSearchBar";
import Box from "@mui/material/Box";
import NetworkAdapterProvider from "../components/networkAdapters/NetworkAdapterProvider";
import NetworkAdapterTable from "../components/networkAdapters/NetworkAdpaterTable";
import { useTranslation } from "react-i18next";

function NetworkAdapters() {
  const { t } = useTranslation();
  return (
    <Box>
      <NetworkAdapterProvider>
        <h1> {t("network_adapters")}</h1>
        <Box sx={{ marginBottom: 5 }}>
          <NetworkAdapterTable></NetworkAdapterTable>
        </Box>
      </NetworkAdapterProvider>
    </Box>
  );
}

export default NetworkAdapters;
