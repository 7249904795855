import React from "react";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";

const Circle = styled("div")(({ theme }) => ({
  width: 28,
  height: 28,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: 8,
  margin: 5,
}));

const CircleComponent = ({ value }) => {
  return (
    <Tooltip title={value}>
      <Circle>{value}</Circle>
    </Tooltip>
  );
};

export default CircleComponent;
