export const deviceMessageApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getDeviceMessages: build.query({
        query: ({ digitalTwinId, deviceId, from, to, page, perPage }) => {
          return `v1/altior/digital_twin/${digitalTwinId}/instance/${deviceId}/message?from_date=${from}&to_date=${to}&page=${page}&per_page=${perPage}&message_type=reading&sort=message_date&order=desc`;
        },
        transformResponse: (response) => {
          console.log("rspshcka", response);
          const data = response?.data?.map((value) => {
            if (
              value?.attributes?.values?.class === "MAD:00:13" ||
              value?.attributes?.values?.class === "EFE:00:13" ||
              value?.attributes?.values?.class === "BMT:11:13" ||
              value?.attributes?.values?.class === "SON:27:13" ||
              value?.attributes?.values?.class === "EFE:00:04" ||
              value?.attributes?.values?.class === "MAD:00:04" ||
              value?.attributes?.values?.values?.value?.type == "04" ||
              value?.attributes?.values?.values?.value?.type == "13"
            ) {
              return value;
            } else {
              return {
                id: value.id,
                messageDate: value.attributes.values?.read_time,
                deviceId: value.attributes.device_id,
                messageType: value.attributes.message_type,
                parentDeviceId: value.attributes.parent_device_id,
                deviceIdentifier: value?.attributes?.values?.device_identifier,
                valueType: value?.attributes?.values?.value_type,
                value:
                  value?.attributes?.values?.values?.value?.last_reading?.value,
                k: value?.attributes?.values?.values?.value?.k,
                unit: value?.attributes?.values?.values?.value?.last_reading
                  ?.unit,
                last_unconverted_reading:
                  value.attributes.values.values.value.last_unconverted_reading,
                strValue: value?.attributes?.values?.values?.value?.last_reading
                  ?.value
                  ? `${value?.attributes?.values?.values?.value?.last_reading?.value} ${value?.attributes?.values?.values?.value?.last_reading?.unit}`
                  : null,
                // inAlarm: value?.attributes?.values?.value?.alarms.length > 0,
                alarms: value?.attributes?.values?.values?.value?.alarms,
              };
            }
          });
          return { data: data, meta: response.meta };
        },

        extraOptions: { maxRetries: 1 },
      }),
    }),
  });

/* export const { useLazyGetDeviceMessagesQuery } = deviceMessageApi; */
