import * as React from "react";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";

import "dayjs/locale/it";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import { Grid } from "@material-ui/core";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import CircleComponent from "./Circle";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DatePicker, Calendar } from "@mui/x-date-pickers/DatePicker";
import { getMonthStartAndEndUTC } from "../../../utils/utilsFunctions";
import { useParams } from "react-router-dom";
import theme from "../../../theme";
import { useDebounce } from "react-use";
import { stubTrue } from "lodash";

const BadgeDatePicker = (props) => {
  const defaultEndtDate = props?.schema?.properties?.to_date?.default;
  const label = props?.schema?.label;
  const [getCalendatData, { isPending, isFetching }] =
    store.useLazyGetCalendatValuesQuery();
  const { t } = useTranslation();
  const [allReadings, setAllReadings] = useState(false);
  const { id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const requestAbortController = React.useRef(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState();
  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  const [lastMonth, setLastMonth] = useState(null);
  const currentLanguage = i18n.language;
  const [firstRender, setFirstRender] = useState(true);
  const [block, setBlock] = useState(false);
  const [monthSetted, setMotnhSetted] = useState(false);
  const formatDateString = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const data = useSelector(
    (state) => state.condominiumSlice?.calendarData
  )?.map((item) => ({
    ...item,
    datetime: formatDateString(item?.datetime),
  }));
  const tab = useSelector((state) => state.condominiumSlice?.readingTableTab);
  const [endDate, setEndDate] = useState(
    defaultEndtDate ? defaultEndtDate : null
  );
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];
  const transformedData = data?.map((item) => ({
    ...item,
    datetime: t("date_val", {
      val: new Date(item?.datetime),
    }),
  }));
  const currentDate = new Date();

  const nextWeekDate = new Date(currentDate);
  nextWeekDate.setDate(nextWeekDate.getDate() + 7);

  const isoFormatDate = nextWeekDate.toISOString();

  const newTo = new Date(endDate);
  useEffect(() => {
    console.log("currentLanguage", currentLanguage);
  }, [currentLanguage]);
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;
  /*   useEffect(() => {
    console.log("fromdtwid", props);
    if (props?.formData?.to_date == null) {
    

      setEndDate(null);
    }
  }, [props]);
 */
  useEffect(() => {
    if (props?.schema?.default && firstRender) {
      setEndDate(props?.schema?.default);
      setFirstRender(false);
    }
  }, [props]);
  useEffect(() => {
    if (newTo == "Invalid Date") {
      props.onChange({
        to: null,
      });
    }
  }, [newTo]);
  const faked = [
    {
      condominium_id: 6221,
      condominium_identifier: "9fb3129a-8ba2-11ee-8516-005056466452",
      datetime: "1/4/2024",
      hca_percentage: null,
      hca_total_meter: null,
      hca_total_reading: null,
      heat_meter_percentage: 108.33333333333333,
      heat_meter_total_meter: 12,
      heat_meter_total_reading: 13,
      inserted_at: "2024-06-11 08:25:21",
      updated_at: "2024-06-11 08:25:21",
      water_percentage: 100,
      water_total_meter: 33,
      water_total_reading: 33,
    },
  ];
  useEffect(() => {
    if (newTo != "Invalid Date" && endDate !== null) {
      const end = new Date(endDate);
      const to = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
      );
      const from = new Date(startDate);
      const start = new Date(
        Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())
      );
      const toIso = to.toISOString();

      if (!allReadings) {
        props.onChange({
          to_date: to ? to.toISOString() : null,
        });
      } else {
        props.onChange({
          to_date: to ? to.toISOString() : null,
          from_date: start.toISOString(),
        });
      }
    }
  }, [endDate, startDate]);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setCurrentMonth(date);
  };
  useEffect(() => {
    if (currentMonth && monthSetted) {
      setLastMonth(currentMonth);
    }
  }, [currentMonth, monthSetted]);
  useEffect(() => {
    if (props.defaultDate) {
      setEndDate(isoFormatDate);
    }
  }, []);
  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);

      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);
  useEffect(() => {
    console.log("propsiki", props);
  }, [props]);

  useEffect(() => {
    const debouncedGetCalendarData = debounce(() => {
      if (open && currentMonth !== lastMonth) {
        const { startOfMonthUTC, endOfMonthUTC } = getMonthStartAndEndUTC(
          currentMonth.toISOString()
        );
        setBlock(stubTrue);
        getCalendatData({
          id: id,
          params: {
            from: startOfMonthUTC,
            to: endOfMonthUTC,
          },
        });
      }
    }, 500);

    debouncedGetCalendarData();

    return () => debouncedGetCalendarData.cancel();
  }, [open, currentMonth]);

  useEffect(() => {
    console.log("trsfrmdt", isFetching);
  }, [isFetching]);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const openFunc = () => {
    setOpen(true);
    setMotnhSetted(true);
  };
  const getDayValue = (date) => {
    const formattedDate = formatDate(date);
    const dayData = data?.find((item) => item.datetime === formattedDate);

    if (tab == "afs" || tab == "acs") {
      return dayData?.water_percentage ? dayData?.water_percentage : null;
    }
    if (tab == "hca") {
      return dayData?.hca_percentage ? dayData?.hca_percentage : null;
    }
    if (tab == "heat") {
      return dayData?.heat_meter_percentage
        ? dayData?.heat_meter_percentage
        : null;
    }
  };
  const colorSelector = (val) => {
    if (val < 30) {
      return theme.palette.error.main;
    }
    if (val > 30 && val < 60) {
      return theme.palette.warning.main;
    }
    if (val > 60) {
      return theme.palette.success.main;
    }
  };

  const onCheckBoxClick = () => {
    setAllReadings(!allReadings);
  };

  useEffect(() => {
    console.log("ckbx", allReadings);
  }, [allReadings]);

  return (
    <>
      {!allReadings && (
        <Grid container>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={currentLanguage.split("-")[0]}
          >
            <Grid item xs={12}>
              <DatePicker
                style={{ width: 400, height: 400 }}
                renderInput={(props) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...props}
                    error={
                      EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                    }
                    helperText={
                      EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                    }
                  />
                )}
                onMonthChange={handleMonthChange}
                label={label ? t(label) : t("end_date")}
                value={endDate}
                onOpen={openFunc}
                onClose={() => setOpen(false)}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderDay={(day, _value, DayComponentProps) => {
                  const dayValue = getDayValue(day.toDate());
                  const isSelected = dayValue !== null;
                  const dayOfMonth = dayjs(day).format("DD"); // Используем dayjs для форматирования даты
                  const formattedDate = dayOfMonth.padStart(2, "0");
                  console.log(
                    "Rendering day:",
                    day.toDate(),
                    "Value:",
                    dayValue
                  );

                  return (
                    <Badge
                      key={day.toString()}
                      overlap="circular"
                      badgeContent={
                        isSelected && (
                          /*   <CircleComponent value={`${dayValue.toFixed(1)}%`} /> */
                          <Tooltip
                            title={dayValue && `${dayValue?.toFixed(0)}`}
                          >
                            <Typography
                              sx={{
                                fontSize: 10,
                                mb: 0.7,
                                ml: 0.3,
                                fontWeight: "bold",
                                color: colorSelector(dayValue?.toFixed(0)),
                                /*   zIndex: 9999, */
                              }}
                            >{`${dayValue?.toFixed(0)}%`}</Typography>
                          </Tooltip>
                        )
                      }
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      bad
                    >
                      <Tooltip title={dayValue && `${dayValue?.toFixed(0)}`}>
                        <PickersDay
                          {...DayComponentProps}
                          sx={{
                            margin: "3px",
                            width: "35px",
                            height: "35px",
                            fontSize: "1.25rem",
                            "&.Mui-selected": {
                              marginTop: 0.8,
                              backgroundColor: "primary.main",
                              width: "28x",
                              height: "28px",
                            },
                          }}
                        >
                          <Typography>{formattedDate}</Typography>
                        </PickersDay>
                      </Tooltip>
                    </Badge>
                  );
                }}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      )}
      {allReadings && (
        <Grid container>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={currentLanguage.split("-")[0]}
          >
            <Grid item xs={6}>
              <DatePicker
                renderInput={(props) => (
                  <TextField sx={{ width: "99%" }} {...props} />
                )}
                label={t("start_date")}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ ml: 2 }}>
              <DatePicker
                renderInput={(props) => (
                  <TextField sx={{ width: "100%" }} {...props} />
                )}
                label={t("end_date")}
                value={endDate}
                onChange={(newValue) => {
                  /*   setDate((prev) => ({
                ...prev,
                end: new Date(newValue.toString()),
              })); */
                  setEndDate(newValue);
                }}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      )}
      <FormControlLabel
        control={<Checkbox checked={allReadings} onChange={onCheckBoxClick} />}
        label={t("all_readings")}
      />
    </>
  );
};

export default BadgeDatePicker;
