import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const BlockConsumptions = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props?.schema?.default);
  const valueHandler = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    props.onChange(value);
  }, [value]);
  useEffect(() => {
    console.log("asdasdasdrrr", props);
  }, [props]);
  return (
    <Box display={"flex"}>
      <TextField
        fullWidth
        sx={{ mr: 3 }}
        value={t("block_consumption_computation")}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("value")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={t("value")}
          onChange={(e) => valueHandler(e)}
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default BlockConsumptions;
