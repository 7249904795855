import React, { useEffect, useState } from "react";
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils,
} from "react-awesome-query-builder";
// For MUI 4/5 widgets only:
// import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import DexTable from "../components/dex/DexTable";
import Loader from "../components/Loader";
import BasicSelect from "../components/dex/DexDataSource";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useSelector, useDispatch } from "react-redux";
import { setDexAttributes } from "../redux/slices/dexDataSourceSlice";
import { setSavedValue } from "../redux/slices/dexDataSourceSlice";
import { TextareaAutosize } from "@mui/base";
import { clearDexShow } from "../redux/slices/dexDataSourceSlice";

import ExportDexButton from "../components/form/formComponents/ExportDexButton";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../components/meters/ModalDialog";
const InitialConfig = MuiConfig;

const types = {
  ...InitialConfig.types,
};
const operators = {
  ...InitialConfig.operators,
  // examples of  overriding

  //   between: {
  //     ...InitialConfig.operators.between,
  //     textSeparators: ["from", "to"],
  //   },
  sum: {
    label: "Sum",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `SUM(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },

  count_distinct: {
    label: "Count Distinct",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(distinct(${field}))`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  count: {
    label: "Count",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `count(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }];
      return result;
    },
  },
  average: {
    label: "Average",
    reversedOp: null,
    labelForFormat: "",
    cardinality: 0,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    sqlFormatOp: (field, op, value) => `average(${field})`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result["AVG"] = [{ var: field.var }];
      return result;
    },
  },
  like: {
    label: "LIKE",
    reversedOp: null,
    labelForFormat: "LIKE*",
    cardinality: 1,
    // formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
    //   `${field} ${opDef.labelForFormat} ${value}`,
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }, value];
      return result;
    },
  },
  test: {
    label: "test",
    reversedOp: "not_test",
    labelForFormat: "test",
    cardinality: 1,
    formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
      `${field} ${opDef.labelForFormat} ${value}`,
    mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => {
      let key = `${op}`;
      var result = {};
      result[key] = [{ var: field.var }, value];
      return result;
    },
  },
  contains: {
    label: "Contains",
    labelForFormat: "Contains",
    reversedOp: "not_like",
    sqlOp: "LIKE",
    spelOp: ".contains",
    spelOps: ["matches", ".contains"],
    // mongoFormatOp: mongoFormatOp1.bind(null, "$regex", function (v) {
    //   return typeof v == "string" ? (0, _stuff.escapeRegExp)(v) : undefined;
    // }, false),
    //jsonLogic: (field, op, val) => ({ "in": [val, field] }),
    jsonLogic: "in",
    _jsonLogicIsRevArgs: true,
    valueSources: ["value"],
    elasticSearchQueryType: "regexp",
  },

  //   cane: {
  //     label: "sum",
  //     isNotOp: true,
  //     reversedOp: "not_equal",
  //     labelForFormat: "sum",
  //     cardinality: 1,
  //     formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
  //       `${opDef.labelForFormat}(${field})`,
  //     sqlFormatOp: (field, op, value) => `SUM(${field})`,
  //     mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
  //   },
};

const viewFields = {
  device_id: {
    label: "DeviceId",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  parent_device_id: {
    label: "Parent Device Id",
    type: "number",
    fieldSettings: {
      min: 0,
    },
    valueSources: ["value"],
    operators: ["not_equal", "equal"],
  },
  in_error: {
    label: "In Error",
    type: "boolean",

    valueSources: ["value"],
    // operators: ["sum", "equal"],
  },
  inserted_at: {
    label: "Date system entry",
    type: "datetime",

    valueSources: ["value"],
    //   operators: ["sum", "equal"],
  },
  message_date: {
    label: "Reading Date",
    type: "datetime",
    valueSources: ["value"],
  },
  unit: {
    label: "Unit",
    type: "string",
    valueSources: ["value"],
  },
  // rssi: {
  //   label: "RSSI",
  //   type: "number",
  //   valueSources: ["value"],
  //   operators: [
  //     "sum",
  //     "count",
  //     "average",
  //     "not_equal",
  //     "equal",
  //     "less",
  //     "less_or_equal",
  //     "greater",
  //     "greater_or_equal",
  //   ],
  // },
  volume: {
    label: "Volume",
    type: "number",
    valueSources: ["value"],
    operators: [
      "sum",
      "count",
      "average",
      "not_equal",
      "equal",
      "less",
      "less_or_equal",
      "greater",
      "greater_or_equal",
    ],
  },
};

const config = {
  ...InitialConfig,
  operators: operators,
  types: types,
  showNot: false,
  fields: {},
};

const queryValue = { id: QbUtils.uuid(), type: "group" };
const fakeQueryValue = { id: QbUtils.uuid(), type: "group" };

const Dex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logic, setLogic] = useState(null);
  const [testConfig, setTestConfig] = useState(null);
  const [saveDexModal, setModal] = useState(false);
  const [createDex, { isSuccess: createDexSuccess }] =
    store.useCreateDexMutation();
  const [createdId, setCreatedId] = useState(null);
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);
  const [firstRender, setFirstRender] = useState(true);
  const [perPage] = useState(15);
  const [description, setDescription] = useState("");
  const [postDex] = store.usePostDexMutation();
  const [jsonTree, setJsonTree] = useState(null);
  const dexAttributes = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes
  );
  const dataSources = useSelector(
    (state) => state.dexDataSourceSlice.dataSources
  );
  /*  const page = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.page
  );
  const perPage = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.per_page
  ); */
  const postOrUpdate = useSelector(
    (state) => state?.dexDataSourceSlice?.postOrUpdate
  );
  const dataSource = useSelector(
    (state) => state.dexDataSourceSlice.dataSource
  );
  const nameHandle = (e) => {
    setName(e.target.value);
  };
  const descHandle = (e) => {
    setDescription(e.target.value);
  };
  const { t } = useTranslation();
  const { post, loading, error, del, patch } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  useEffect(() => {
    if (dataSource) {
      const newConfig = { ...config, fields: dataSource.fields };
      setState({ ...state, config: newConfig });
    }
  }, [dataSource]);
  // const [dataSource, setDataSource] = useState();
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  });
  const [fakeState, setFakeState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(fakeQueryValue), config),
    config: config,
  });
  useEffect(() => {
    if (logic) {
      const newConfig = { ...config, fields: dataSource?.fields };
      const immutableTree = QbUtils.loadFromJsonLogic(logic, newConfig);
      setFakeState({
        tree: immutableTree,
        config: newConfig,
      });
    }
  }, [logic]);
  useEffect(() => {
    setFirstRender(false);
  }, []);
  const [data, setData] = useState();
  useEffect(() => {
    console.log("state changed", state);
  }, [state]);
  useEffect(() => {
    console.log("logic", QbUtils.loadFromJsonLogic(logic, config));
  }, [logic]);

  const executeDex = async () => {
    setPage(1);
    setLogic(QbUtils.jsonLogicFormat(state.tree, config).logic);
    console.log("bored", QbUtils.jsonLogicFormat(state.tree, config));

    const attributes = {
      logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
      data_source: dataSource.name,
      page: 1,
      per_page: perPage,
    };
    postDex(attributes);

    dispatch(setDexAttributes(attributes));
    /*    const result = await post("/api/v1/dex", body); */
    /*    setData(result); */
  };
  /*  useEffect(() => {
    if (postOrUpdate) {
      postDex(dexAttributes);
    }
  }, [postOrUpdate]); */
  useEffect(() => {
    dispatch(clearDexShow());
  }, []);
  useEffect(() => {
    if (!firstRender) {
      const attributes = {
        logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
        data_source: dataSource.name,
        page: page,
        per_page: perPage,
      };
      postDex(attributes);
    }
  }, [page]);
  useEffect(() => {
    if (createdId) {
      navigate(`/dex/${createdId}`);
    }
  }, [createdId]);
  const renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div>
        Query string:{" "}
        <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre>
      </div>
      <div>
        MongoDb query:{" "}
        <pre>
          {JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}
        </pre>
      </div>
      <div>
        SQL where:{" "}
        <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
      </div>
      <div>
        JsonLogic:{" "}
        <pre>
          {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}
        </pre>
      </div>
    </div>
  );

  useEffect(() => {
    console.log("pizdec", state.tree);
  }, [state]);

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setTestConfig(config);
    /*   setLogic(immutableTree); */
    console.log("jsonTree", immutableTree, config);
    setState({ tree: immutableTree, config: config });
    setJsonTree(QbUtils.getTree(immutableTree));
  };
  useEffect(() => {
    console.log("jsonTreesu", QbUtils.getTree(state.tree));
    dispatch(setSavedValue(QbUtils.getTree(state.tree)));
    localStorage.setItem(
      "treevalue",
      JSON.stringify(QbUtils.getTree(state.tree))
    );
  }, [state]);
  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );
  const onSaveClick = () => {
    console.log("name", name);
    console.log("desc", description);
    console.log("logic", QbUtils.jsonLogicFormat(state.tree, config).logic);
    const attributes = {
      logic: QbUtils.jsonLogicFormat(state.tree, config).logic,
      tree: QbUtils.getTree(state.tree),
      data_source: dataSource.name,
      page: page,
      per_page: perPage,
      name: name,
      description: description,
    };
    createDex(attributes)
      .unwrap()
      ?.then((data) => setCreatedId(data?.data?.id));
  };
  return (
    <Box>
      <ModalDialog
        open={saveDexModal}
        title={t("save_dex")}
        close={closeModal}
        cancell={false}
      >
        <Box sx={{ width: 500, mt: 2 }}>
          <TextField
            onChange={(e) => nameHandle(e)}
            value={name}
            label={t("name")}
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            rows={5}
            value={description}
            multiline
            onChange={(e) => descHandle(e)}
            label={t("description")}
            fullWidth
          />
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            <Button onClick={closeModal} variant="contained">
              {t("close")}
            </Button>
            <Button onClick={onSaveClick} variant="contained">
              {t("save")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
      <h3 style={{ marginBottom: 20 }}> Dex - Data explorer</h3>
      {loading ? <Loader /> : <></>}
      <Box>
        <BasicSelect
        // onChange={(dataSourceSelected) => setDataSource(dataSourceSelected)}
        />
      </Box>
      <Box>
        <Query
          {...state.config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
        {/* <Query
          {...fakeState.config}
          value={fakeState.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <Button
          onClick={executeDex}
          disabled={!dataSource || !jsonTree?.children1?.[0]?.properties?.field}
          endIcon={<SettingsSuggestIcon />}
        >
          {t("execute")}
        </Button>
        <Box display="flex">
          <Button variant="contained" sx={{ mr: 1 }} onClick={openModal}>
            {t("save_dex")}
          </Button>
          <ExportDexButton
            dex={{
              logic: QbUtils.jsonLogicFormat(state.tree, config).logic,

              data_source: dataSource?.name,
            }}
          />
        </Box>
        {/*  <Box sx={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label={t("data_explorer_name")}
            variant="outlined"
            disabled={!dataSource}
          />
          <Button disabled={!dataSource}>{t("save")}</Button>
        </Box> */}
      </Box>
      <DexTable page={page} perPage={perPage} setPage={setPage} />
    </Box>
  );
};

export default Dex;
