import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { setSelectedUser } from "../../redux/slices/userSlice";

import { useLazyGetUsersQuery } from "../../redux/slices/userApi";
import { useDispatch, useSelector } from "react-redux";

const UserWidget = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultProp = props?.schema?.default ?? props.default;
  const err = props?.rawErrors?.[0];
  const update = props?.schema?.update;
  const [firstRender, setFirstRender] = useState(true);
  const users = useSelector((state) => state?.userSlice?.users);
  const mappedUsers = users?.map((user) => ({
    label: `${user?.attributes?.first_name} ${user?.attributes?.last_name}`,
    id: user?.attributes?.id,
  }));

  const [getUsers] = store.useLazyGetUsersQuery();
  const [value, setValue] = useState();
  useEffect(() => {
    getUsers();
  }, []);
  const defaultValue = mappedUsers?.filter(
    (item) => item.id == defaultProp
  )?.[0];
  useEffect(() => {
    dispatch(setSelectedUser(""));
  }, []);
  useEffect(() => {
    if (defaultValue) {
      dispatch(setSelectedUser(defaultValue.label));
    }
  }, [defaultValue]);
  useEffect(() => {
    if (value) {
      props.onChange(value.id ?? defaultValue.id);
    } else {
      props.onChange(update ? defaultValue?.id : "");
    }
  }, [value, defaultValue]);

  useEffect(() => {
    console.log("chekdefval", defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    console.log("pisyapopasda", value);
  }, [value]);

  return (
    <>
      {mappedUsers && !update && (
        <Autocomplete
          fullWidth
          disablePortal
          value={value}
          id="combo-box-demo"
          options={mappedUsers ?? []}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              error={err}
              {...params}
              label={props?.label ?? t("user")}
            />
          )}
        />
      )}
      {mappedUsers && defaultValue && update && (
        <Autocomplete
          fullWidth
          disablePortal
          value={value}
          id="combo-box-demo"
          options={mappedUsers ?? []}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              error={err}
              {...params}
              label={props?.label ?? t("user")}
            />
          )}
        />
      )}
      {!mappedUsers && (
        <Autocomplete
          fullWidth
          disablePortal
          disabled
          id="combo-box-demo"
          options={[]}
          renderInput={(params) => (
            <TextField {...params} label={props?.label ?? t("user")} />
          )}
        />
      )}
    </>
  );
};
export default UserWidget;
