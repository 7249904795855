import { authApi } from "./authApi";
import { CampaignApi } from "./CampaignApi";
import { condominiumApi } from "./condominiumApi";
import { meterApi } from "./meterApi";
import { userApi } from "./userApi";
import { gatewayApi } from "./gatewayApi";
import { dashboardApi } from "./dashboardApi";
import { aegisApi } from "./aegisApi";
import { clusterApi } from "./clusterApi";
import { deviceDistrictApi } from "./deviceDistrictApi";
import { deviceExportApi } from "./deviceExportApi";
import { deviceMessageApi } from "./deviceMessageApi";
import { dexApi } from "./dexApi";
import { digitalTwinApi } from "./digitalTwinApi";
import { dataApi } from "./GetDataRtq";
import { worklistApi } from "./worklistApi";
import { messageApi } from "./messageApi";
import { actionsApi } from "./actionsApi";
import { loraApi } from "./loraApi";
import { messageExportApi } from "./messageExportApi";
import { jobsApi } from "./jobsApi";
import { meterReadingGraphApi } from "./meterReadingGraphApi";
import { positionApi } from "./positionApi";
import { digitalTwinInstanceRtk } from "./QdtiSlice";
import { statisticApi } from "./statisticApi";
import { UserGroupApi } from "./UserGroupApi";
import { uploadFileApi } from "./uploadFileApi";
import { userOTPApi } from "./userOTPApi";
import { userPreferenceApi } from "./userPreferenceApi";
import { widgetsApi } from "./widgetsApi";
import { errorTypesApi } from "./errorTypesApi";
import { errorAssociationApi } from "./errorAssociationApi";
import { trilliantApi } from "./trilliantApi";
import { alarmsApi } from "./alarmsApi";
import { notesApi } from "./notesApi";
import { auditApi } from "./auditApi";
import { anomaliesApi } from "./anomaliesApi";
import { inviteApi } from "./inviteApi";
import { balanceApi } from "./balanceApi";
import { configurationApi } from "./configurationApi";
const modules = [
  {
    api: actionsApi,
    keys: [
      "useLazyGetActionsIndexQuery",
      "useLazyGetActionsShowQuery",
      "approveActions",
    ],
    endpointKey: "actions",
  },
  {
    api: jobsApi,
    keys: [
      "useLazyGetJobsQuery",
      "useStopJobMutation",
      "useApproveActionsMutation",
    ],
    endpointKey: "jobs",
  },
  {
    api: balanceApi,
    keys: [
      "useCreateBalanceMutation",
      "useLazyGetBalanceIndexQuery",
      "useDeleteBalanceMutation",
      "useLazyGetBalanceShowQuery",
      "useUpdateBalanceMutation",
      "useLazyGetBalanceValuesQuery",
    ],
    endpointKey: "balance",
  },
  {
    api: worklistApi,
    keys: [
      "useLazyGetWorklistEditorIndexQuery",
      "useLazyGetWorklistPolygonQuery",
      "useLazyGetWorklistEditorShowQuery",
      "useCreateWorklistEditorMutation",
      "useCreateWorklistPolygonMutation",
      "useDeleteWorkListEditorMutation",
      "useUpdateWorklistEditorMutation",
      "useCreateWorkListMutation",
      "useLazyGetWorklistShowQuery",
      "useLazyGetWorklistIndexQuery",
      "useUpdateWorklistMutation",
      "useDeleteWorklistMutation",
      "useDeleteWorklistPolygonMutation",
    ],
    endpointKey: "worklist",
  },
  {
    api: configurationApi,
    keys: [
      "useLazyGetShowConfigurationQuery",
      "useSetConfigurationMutation",
      "useLazyGetConfigIndexQuery",
      "useLazyGetConfigShowQuery",
      "useCreateConfigurationMutation",
      "useUpdateConfigurationMutation",
      "useDeleteConfigurationMutation",
    ],
    endpointKey: "configuration",
  },
  {
    api: inviteApi,
    keys: ["useLazyGetInviteListQuery"],
    endpointKey: "invite",
  },
  {
    api: UserGroupApi,
    keys: [
      "useCreateUserGroupMutation",
      "useUpdateUserGroupMutation",
      "useLazyGetUserGroupsQuery",
      "useLazyGetUserGroupShowQuery",
      "useDeleteUserGroupMutation",
      "useBlockGroupAssociateMutation",
      "useLazyGetBlockGroupsQuery",
      "useDeleteBlockGroupMutation",
      "useUserGroupAssociateMutation",
      "useLazyGetIndexUserGroupsQuery",
      "useDeleteUsersGroupMutation",
      "useAssocBlockToGroupMutation",
      "useDisasocBlockFromGroupMutation",
      "useLazyGetGroupBlocksQuery",
    ],
    endpointKey: "userGroup",
  },
  {
    api: trilliantApi,
    keys: [
      "useExportTrilliantMetersMutation",
      "useLazyGetTrilliantMetersQuery",
      "useDeleteTrilliantMeterMutation",
    ],
    endpointKey: "trilliant",
  },
  {
    api: anomaliesApi,
    keys: ["useLazyGetAomalyIndexQuery"],
    endpointKey: "anomalies",
  },
  {
    api: loraApi,
    keys: [
      "useLazyGetIndexLoraQuery",
      "useLazyGetLoraShowQuery",
      "useCreateLoraMutation",
      "useDeleteLoraMutation",
    ],
    endpointKey: "lora",
  },
  {
    api: auditApi,
    keys: ["useLazyGetAuditIndexQuery"],
    endpointKey: "audit",
  },
  {
    api: notesApi,
    keys: [
      "useLazyGetNotesQuery",
      "useLazyGetShowNoteQuery",
      "useCreateNoteMutation",
      "useUpdateNoteMutation",
      "useDeleteNoteMutation",
    ],
    endpointKey: "notes",
  },
  {
    api: alarmsApi,
    keys: ["useLazyGetAlarmsQuery"],
    endpointKey: "alarms",
  },
  {
    api: errorAssociationApi,
    keys: [
      "useLazyGetErrorAssociationsQuery",
      "useLazyGetErrorAssociationShowQuery",
      "useCreateErrorAssociationMutation",
      "useUpdateErrorAssociationMutation",
      "useDeleteErrorAssociationMutation",
    ],
    endpointKey: "errorAssociation",
  },
  {
    api: errorTypesApi,
    keys: [
      "useLazyGetErrorTypesQuery",
      "useLazyGetErrorTypeShowQuery",
      "useCreateErrorTypeMutation",
      "useUpdateErrorTypeMutation",
      "useDeleteErrorTypeMutation",
    ],
    endpointKey: "errorTypes",
  },
  {
    api: widgetsApi,
    keys: [
      "useLazyGetWidgetsQuery",
      "useLazyGetShowWidgetsQuery",
      "useCreateWidgetMutation",
      "useUpdateWidgetMutation",
      "useDeleteWidgetMutation",
    ],
    endpointKey: "widgets",
  },
  {
    api: userPreferenceApi,
    keys: ["useSetPreferenceMutation", "useLazyGetPreferenceQuery"],
    endpointKey: "userPreference",
  },
  {
    api: userOTPApi,
    keys: [
      "useDeleteOTPMutation",
      "useCreateOTPMutation",
      "useVerifyOTPMutation",
    ],
    endpointKey: "userOTP",
  },
  {
    api: uploadFileApi,
    keys: [
      "useLazyGetFileErrorsQuery",
      "useLazyGetFileStatisticQuery",
      "usePostFileMutation",
    ],
    endpointKey: "uploadFile",
  },
  {
    api: statisticApi,
    keys: [
      "useCreateStatisticMutation",
      "useLazyGetStatisticsQuery",
      "useLazyGetStatisticShowQuery",
      "useUpdateStatisticMutation",
      "useDeleteStatisticMutation",
    ],
    endpointKey: "statistic",
  },
  {
    api: digitalTwinInstanceRtk,
    keys: ["usePostDigitalTwinInstanceMutation"],
    endpointKey: "digitalTwinInstance",
  },
  {
    api: positionApi,
    keys: ["usePostPositionMutation"],
    endpointKey: "position",
  },
  {
    api: meterReadingGraphApi,
    keys: ["useLazyGetMeterReadingGraphQuery"],
    endpointKey: "meterReadingGraph",
  },
  {
    api: messageExportApi,
    keys: [
      "useLazyStartExportMessageQuery",
      "useDownloadMessagesMutation",
      "useLazyStartExportReadingsQuery",
    ],
    endpointKey: "messageExport",
  },
  {
    api: messageApi,
    keys: [
      "useLazyGetDiagnosticMessagesQuery",
      "useLazyGetReadingMessagesQuery",
    ],
    endpointKey: "message",
  },
  {
    api: dataApi,
    keys: [
      "useLazyGetDataQuery",
      "useLazyGetDataShowQuery",
      "useLazyGetDataByNameQuery",
      "useLazyGetWithoutParamQuery",
    ],
    endpointKey: "dataRtk",
  },
  {
    api: digitalTwinApi,
    keys: [
      "useLazyGetDigitalTwinsQuery",
      "useLazyGetDigitalTwinAttributesShowQuery",
    ],
    endpointKey: "digitalTwin",
  },
  {
    api: dexApi,
    keys: [
      "usePostDexMutation",
      "useLazyGetDexIndexQuery",
      "useCreateDexMutation",
      "useLazyGetDexShowQuery",
      "useExecuteSavedDexMutation",
      "useUpdateDexMutation",
      "useExportDexMutation",
    ],
    endpointKey: "dex",
  },
  {
    api: deviceMessageApi,
    keys: ["useLazyGetDeviceMessagesQuery"],
    endpointKey: "deviceMessage",
  },
  {
    api: deviceExportApi,
    keys: [
      "useLazyStartExportQuery",
      "useDownloadMutation",
      "useLazyGatewayStartExportQuery",
      "useGatewayDownloadMutation",
    ],
    endpointKey: "deviceExport",
  },
  {
    api: deviceDistrictApi,
    keys: [
      "useCreateDistrictMutation",
      "useCreatePolygonMutation",
      "useLazyGetPolygonDevicesQuery",
      "useLazyGetDistrictShowQuery",
      "useLazyGetPolygonsQuery",
      "useLazyGetDistrictsQuery",
      "useDeleteDistrictMutation",
      "useUpdateDistrictMutation",
      "useDeletePolygonMutation",
      "useDownloadDevicesMutation",
    ],
    endpointKey: "deviceDistrict",
  },
  {
    api: clusterApi,
    keys: [
      "useLazyGetClusterQuery",
      "useLazyGetSingleClusterDevicesQuery",
      "useLazyGetDevicesQuery",
    ],
    endpointKey: "cluster",
  },
  {
    api: aegisApi,
    keys: ["useLazyGetAllrolesQuery"],
    endpointKey: "aegis",
  },
  {
    api: gatewayApi,
    keys: [
      "useLazyGetIndexGatewayQuery",
      "useLazyGetShowGatewayQuery",
      "useCreateGatewayMutation",
      "useDeleteGatewayMutation",
      "useLazyGetGatewayMessagesQuery",
      "useLazyGetMeterReadedMessagesQuery",
      "useLazyGetMeterUnreadedMessagesQuery",
      "useLazyGetHandledMeterMessagesQuery",
      "useGatewayUploadMutation",
      "useLazyGetEdgeMessagesQuery",
      "useLazyGetErrorMeterMessagesQuery",
      "useLazyGetUnseenGwMessagesQuery",
    ],
    endpointKey: "gateway",
  },
  {
    api: userApi,
    keys: [
      "useLazyGetUsersQuery",
      "useLazyGetUserShowQuery",
      "useDeleteUserMutation",
      "useChangeUserRolesMutation",
      "useLazyGetGrantQuery",
      "useCreateUserMutation",
      "useLazyGetCondominiumListQuery",
      "useUpdateUserMutation",
      "useUserCondAssocMutation",
    ],
    endpointKey: "user",
  },
  {
    api: meterApi,
    keys: [
      "useLazyGetConsumptionsQuery",
      "usePostCommandsMutation",
      "useLazyGetMeterAttributesQuery",
      "useUpdateMeterMutation",
      "useLazyGetDigitalTwinQuery",
      "useDeleteMeterMutation",
      "useLazyGetAllReadingsQuery",
      "useLazyGetMeterShowQuery",
      "useImportMeterHcaHistoryMutation",
    ],
    endpointKey: "meter",
  },
  {
    api: authApi,
    keys: ["useSignoutMutation"],
    endpointKey: "auth",
  },
  {
    api: CampaignApi,
    keys: [
      "usePostCampaignMutation",
      "useLazyGetIndexCampaignQuery",
      "useLazyGetShowCampaignQuery",
      "useStartCampaignMutation",
      "useStopCampaignMutation",
      "useDeleteCampaignMutation",
    ],
    endpointKey: "campaign",
  },
  {
    api: dashboardApi,
    keys: [
      "useUpdateWidgetsIdMutation",
      "useCreateNewDashboardMutation",
      "useDeleteDashboardMutation",
      "useLazyGetDashboardListQuery",
      "useLazyGetDashboardShowQuery",
    ],
    endpointKey: "dashboard",
  },
  {
    api: condominiumApi,
    keys: [
      "useUpdateApartmentMutation",
      "useUpdateSubCondominumMutation",
      "useLazyCondominiumIndexQuery",
      "useLazyCondominiumShowQuery",
      "useCreateCondominiumMutation",
      "useCreateSubCondominiumMutation",
      "useCreateApartmentMutation",
      "useLazyGetCondominiumIdQuery",
      "useLazyGetSubCondominiumIdQuery",
      "useUpdateCondominiumMutation",
      "useUpdateSubCondominiumMutation",
      "useAssociateMeterMutation",
      "useLazyGetCondominiumReadingsQuery",
      "useLazyGetSubCondominiumReadingsQuery",
      "useLazyGetApartamentReadingsQuery",
      "useLazyGetCondominiumConsumptionsQuery",
      "useLazyGetSubcondominiumConsumtionsQuery",
      "useLazyGetApartamentConsumtionsQuery",
      "useUpdateCondominiumIfnoMutation",
      "useAssociateGatewayMutation",
      "useLazyGetApartamentIdQuery",
      "useLazyGetMetertIdQuery",
      "useCreateInstanceMutation",
      "useLazyGetSubCondominiumBotElementQuery",
      "useLazyGetSubCondominiumTopElementQuery",
      "useUpdateCondominiumUploadMutation",
      "useExportCondominiumReadingsMutation",
      "useDeleteCondominiumMutation",
      "useExportCondominiumConsumptionsMutation",
      "useStopCondominiumImportMutation",
      "useAssociateAdminMutation",
      "useDisassocAdminMutation",
      "useLazyGetAssociatedUsersQuery",
      "useLazyLockUsersQuery",
      "useLazyUnlockUsersQuery",
      "useLazyGetUsersStatusQuery",
      "useDisassociateApartmentMutation",
      "useDeleteApartmentMutation",
      "useDeleteSubblockMutation",
      "useLazyGetFreeMetersQuery",
      "useSubstitutionMeterMutation",
      "useLazyGetBlockStatsQuery",
      "useLazyGetTotalReadingsQuery",
      "useDissociateMeterMutation",
      "useLazyGetCalendatValuesQuery",
      "useImportHcaHistoryMutation",
      "useCreateAssocMeterMutation",
      "useCreateAndReplaceMeterMutation",
    ],
    endpointKey: "condominium",
  },
];

export default (api) => {
  const result = {};

  modules.forEach(({ api: apiModule, keys, endpointKey }) => {
    const { endpoints, ...functions } = apiModule(api);

    keys.forEach((key) => {
      if (functions[key]) {
        result[key] = functions[key];
      }
    });

    result[`${endpointKey}Endpoints`] = endpoints;
  });

  return result;
};
