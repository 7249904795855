import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { errorAssociationActions } from "../../redux/slices/errorAssociationSlice";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";

import DeleteConfirmModal from "../../utils/DeleteConfirmModal";

const ErrorAssociationIndex = () => {
  const { t } = useTranslation();
  const [trashId, setTrashId] = useState(null);
  const [trashModal, setTrashModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const errorTrashId = useSelector(
    (state) => state?.errorAssociationSlice?.trashId
  );
  const [getErrors, { isLoading }] = store.useLazyGetErrorAssociationsQuery();
  const [getErrorTypes] = store.useLazyGetErrorTypesQuery();
  const [deleteError, { isSuccess: deleteSucccess, isError: errorOnDelete }] =
    store.useDeleteErrorAssociationMutation();
  const errors = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociations
  );
  const errorsCount = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociationsCount
  );
  const errorTypes = useSelector((state) => state?.errorTypeSlice?.errorTypes);
  useEffect(() => {
    getErrors({ page: page, per_page: perPage });
  }, [page]);
  /*   useEffect(() => {
    if (!errorTypes) {
      getErrorTypes();
    }
  }, [errorTypes]); */
  useEffect(() => {
    dispatch(errorAssociationActions.showClean());
  }, []);
  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };

  useEffect(() => {
    if (errorTrashId) {
      deleteError(errorTrashId);
      setTrashId(null);
    }
  }, [errorTrashId]);
  useEffect(() => {
    if (deleteSucccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getErrors({ page: page, per_page: perPage });
    }
  }, [deleteSucccess]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "left",
      sortable: false,
      flex: 2,
      hide: "true",
    },
    {
      field: "class",
      headerName: t("class"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "error_type_id",
      headerName: t("error_type"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "raw_error",
      headerName: t("raw_error"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "inserted",
      headerName: t("inserted_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "updated",
      headerName: t("updated_at"),
      headerAlign: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              onClick={() => onTrashClick(params.value)}
              sx={{ float: "right" }}
            >
              <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
            </IconButton>
            <Link to={`/errorassociation/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = errors?.map((item) => ({
    id: item?.id ?? "--",
    class: item?.class ?? "--",
    error_type_id: item?.error_type?.name,

    raw_error: item.raw_error ?? "--",
    inserted: item?.inserted_at
      ? t("dt", { val: new Date(item?.inserted_at) })
      : "--",
    updated: item?.updated_at
      ? t("dt", { val: new Date(item?.updated_at) })
      : "--",
    actions: item?.id,
  }));
  useEffect(() => {
    console.log("rows", tableRows, errorTypes);
  }, [tableRows]);
  return (
    <>
      {tableRows?.length > 0 && (
        <>
          <DeleteConfirmModal
            trashModal={trashModal}
            setTrashModal={setTrashModal}
            text={t("error_assoc_delete_text")}
            title={t("error_assoc_delete_title")}
            deleteFunc={() =>
              errorAssociationActions.setErrorAssocTrashId(trashId)
            }
          />
          <Typography
            variant="h3"
            sx={{ mb: 3, color: theme.palette.primary.main }}
          >
            {t("association_errors")}
          </Typography>
          <Box sx={{ height: 680 }}>
            <StyledDataGrid
              disableColumnMenu
              rows={tableRows}
              columns={columns}
              rowCount={errorsCount}
              pageSize={perPage}
              rowsPerPageOptions={[perPage]}
              disableSelectionOnClick
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              // loading={isLoadingTable}
              paginationMode="server"
              getRowHeight={() => "auto"}
            />
          </Box>
        </>
      )}
      {tableRows?.length < 1 && !isLoading && (
        <Typography
          variant="h3"
          align="center"
          sx={{ color: theme.palette.primary.main }}
        >
          {t("no_available_data")}
        </Typography>
      )}
    </>
  );
};
export default ErrorAssociationIndex;
