import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import UploadSelect from "./UploadSelect";
import { store } from "../../redux/store";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import LongNameWrapper from "../../utils/LongNameWrapper";
import { CardActionArea } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { setErrors } from "../../redux/slices/errorSlice";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import {
  setCondominiumImportInfo,
  clearCondominiumImportInfo,
} from "../../redux/slices/condominiumSlice";

import { nameTransform } from "../../utils/utilsFunctions";
import theme from "../../theme";
import { loadingOn, loadingOff } from "../../redux/slices/loadingSlice";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { v4 as uuidv4 } from "uuid";
import { useUpdateCondominiumUploadMutation } from "../../redux/slices/condominiumApi";
/* import { setUuid } from "../../redux/slices/uploadFileSlice"; */
import WsConnector from "../../utils/WsConnector";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { Box, Button, Typography, Grid } from "@mui/material";
import ModalDialog from "../meters/ModalDialog";
import { Count } from "./Count";
import { setMessage, setSeverity } from "../../redux/slices/snackbarSlice";

const Uploader = () => {
  const bearer = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [ready, setReady] = useState(false);
  const [msg, setMsg] = useState("");
  const [stopImport, { isSuccess: stopSuccess }] =
    store.useStopCondominiumImportMutation();
  const [confirmImport, { isSuccess: importSuccess }] =
    store.useUpdateCondominiumUploadMutation();
  const { t } = useTranslation();
  const [uploadPhase, setUploadPhase] = useState(1);
  const [importData, setImportData] = useState([]);
  const [firstUpload, setFirstUpload] = useState(true);
  const [createdCondominiumId, setCreatedCondominiumId] = useState(null);
  const [connect, setConnect] = useState(false);
  const formData = new FormData();

  const importInfo = useSelector(
    (state) => state?.condominiumSlice?.importInfo
  );
  const endCommunication = importInfo.findIndex((el) => el?.status === "end");
  const notValidKey = importInfo.findIndex(
    (el) => el?.status === "key_not_valid"
  );
  const missing_k = importInfo.findIndex(
    (el) => el?.status === "missing_k_value"
  );
  const notValidName = importInfo.findIndex(
    (el) => el?.status === "digital_twin_name_not_valid"
  );
  const invalidSubCondName = importInfo.findIndex(
    (el) => el?.status === "error"
  );
  const invalid_name = importInfo.findIndex(
    (el) => el?.status === "error_name"
  );
  const { isWSConnected, channels, data, eventName } = useSelector(
    (state) => state.iwdWebSocketSlice
  );
  const [getErrors, { isSuccess: errorsSuccess }] =
    store.useLazyGetFileErrorsQuery();
  const [getStatistic, { isSuccess: statisticSuccess }] =
    store.useLazyGetFileStatisticQuery();
  const [operationStatus, setOperationStatus] = useState(null);
  const dispatch = useDispatch();
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileIsReady, setFileIsReady] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const operationId = sessionStorage.getItem("uuid");
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  /*   useEffect(() => {
    dispatch(setUuid(uuid));
  }, [uuid]); */
  const isEmpty = Object.entries(formData).length === 1;
  const clearFile = () => {
    setFiles(null);
  };
  const dropzoneStyle = {
    height: "200px",
    border: "1px solid black",
  };
  const customSort = (array) => {
    const order = {
      condominium: 1,
      sub_condominium: 2,
      apartment: 3,
      device: 4,
    };

    const copiedArray = [...array];

    copiedArray.sort((a, b) => {
      const itemA = a.item;
      const itemB = b.item;

      if (order[itemA] < order[itemB]) {
        return -1;
      }
      if (order[itemA] > order[itemB]) {
        return 1;
      }
      return 0;
    });

    return copiedArray;
  };
  useEffect(() => {
    if (importSuccess) {
      /*   navigate(`/condominiums`); */
      dispatch(clearCondominiumImportInfo());
      console.log("zaimportil");
    }
  }, [importSuccess]);
  useEffect(() => {
    console.log("uuidishka", uuid);
  }, [uuid]);
  useEffect(() => {
    if (endCommunication !== -1) {
      dispatch(loadingOff());
      dispatch(actions.cashLean());
      /*  dispatch(actions.cleanData(eventName)); */
    }
  }, [endCommunication]);
  const chekMsgStatus = (msg) => {
    if (msg.action == "check_exists") {
      switch (msg.status) {
        case "ok":
          return t("already_exists");
        case "not_exists":
          return t("not_exists");
        case "key_not_valid":
          setConfirmBlock(true);
          return t("key_not_valid");
        case "digital_twin_name_not_valid":
          setConfirmBlock(true);
          return t("digital_twin_name_not_valid");
        case "error_name":
          return t("sub_condominium_can_not_be_null");
        case "missing_k_value":
          return t("missing_k_value");
      }
    }
    if (msg.action == "create") {
      switch (msg.status) {
        case "ok":
          return t("successfully_created");
        case "error":
          return "Errore nella creazione";
      }
    }
    if (msg.action == "update") {
      switch (msg.status) {
        case "ok":
          return t("successfully_updated");
        case "error":
          return "Errore nell aggiornamento";
      }
    }
    if (msg.action == "load_file") {
      switch (msg.status) {
        case "error":
          return t("file_not_valid");
      }
    }
  };
  const msgColorSelector = (status, action) => {
    if (status === "ok" && action === "check_exists") {
      return theme.palette.warning.main;
    }
    if (status === "ok" && (action === "create" || action === "update")) {
      return theme.palette.success.main;
    }
    if (status === "not_exists") {
      return theme.palette.success.main;
    }
    if (
      status === "error" ||
      status === "error_name" ||
      status === "digital_twin_name_not_valid" ||
      status === "key_not_valid" ||
      status === "missing_k_value"
    ) {
      return theme.palette.error.main;
    }
  };
  useEffect(() => {
    if (importSuccess && endCommunication !== -1) {
      setFirstUpload(false);
    }
  }, [importSuccess, endCommunication]);
  useEffect(() => {
    console.log("piseckapopla", files);
  }, [files]);
  const sortedImportInfo = customSort(importInfo);
  const messageMapper = (msg) => {
    switch (msg?.item) {
      case "condominium":
        return (
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography
              /*  align="center" */
              variant="subtitle2"
              fontsize={15}
              sx={{
                color: msg?.name === null && theme?.palette.error.main,
              }}
            >{`Nome condominio : ${
              msg.name ?? t("not_valid_name")
            }`}</Typography>
            <Typography
              fontsize={15}
              variant="subtitle2"
              /*   align="center" */
              sx={{
                color: msgColorSelector(msg.status, msg.action),
              }}
            >{`Stato condomonio : ${chekMsgStatus(msg)}`}</Typography>
            <Divider variant="horizontal" />
          </Grid>
        );
      case "sub_condominium":
        return (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography
              /*   align="center" */
              variant="subtitle2"
              fontsize={15}
              sx={{
                color: msg?.name === null && theme?.palette.error.main,
              }}
            >{`Nome sottocondominio : ${
              msg.name === "sub_condominium_can_not_be_null"
                ? t("not_valid_name")
                : msg.name
            }`}</Typography>
            <Typography
              fontsize={15}
              /*  align="center" */
              variant="subtitle2"
              sx={{
                color: msgColorSelector(msg.status, msg.action),
              }}
            >{`Stato sottocondominio : ${chekMsgStatus(msg)}`}</Typography>
            <Divider variant="horizontal" />
          </Grid>
        );

      case "apartment":
        return (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              fontsize={15}
              /*   align="center" */
              sx={{
                color: msg?.name === null && theme?.palette.error.main,
              }}
            >{`Nome appartamento : ${
              msg.name === "occupant_name_can_not_be_null"
                ? t("not_valid_name")
                : msg.name
            }`}</Typography>
            <Typography
              fontsize={15}
              variant="subtitle2"
              /*   align="center" */
              sx={{
                color: msgColorSelector(msg.status, msg.action),
              }}
            >{`Stato appartamento : ${chekMsgStatus(msg)}`}</Typography>
            <Divider variant="horizontal" />
          </Grid>
        );
      case "file":
        return (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography
              color={theme.palette.error.main}
              fontsize={15}
              variant="subtitle2"
            >
              {chekMsgStatus(msg)}
            </Typography>
          </Grid>
        );
      case "device":
        return (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography
              fontsize={15}
              variant="subtitle2"
              /*  align="center" */
              sx={
                {
                  /*   color: msg.status !== "ok" && theme.palette.success.main, */
                  /*  : theme.palette.warning.main, */
                }
              }
            >{`Contatore : ${msg.name}`}</Typography>
            <Typography
              fontsize={15}
              variant="subtitle2"
              /* align="center" */
              sx={{
                color: msgColorSelector(msg.status, msg.action),
              }}
            >{`Stato contatore : ${chekMsgStatus(msg)}`}</Typography>
            {msg.action === "check_exists" && (
              <>
                <Typography
                  fontsize={15}
                  variant="subtitle2"
                  /* align="center" */
                  sx={
                    {
                      /*  color: msg.status !== "ok" && theme.palette.success.main, */
                      /* : theme.palette.warning.main, */
                    }
                  }
                >{`Collegato al condominio : ${
                  msg.condominium_name ?? "Nessun condominio"
                }`}</Typography>
                <Typography
                  fontsize={15}
                  variant="subtitle2"
                  /* align="center" */
                  sx={
                    {
                      /*  color: msg.status !== "ok" && theme.palette.success.main, */
                      /*  : theme.palette.warning.main, */
                    }
                  }
                >{`Collegato al sottocondominio : ${
                  msg.sub_condominium_name ?? "Nessun sottocondominio"
                }`}</Typography>
                <Typography
                  fontsize={15}
                  variant="subtitle2"
                  /*  align="center" */
                  sx={
                    {
                      /* color: msg.status !== "ok" && theme.palette.success.main, */
                      /*  : theme.palette.warning.main, */
                    }
                  }
                >{`Collegato al appartamento : ${
                  msg.aparment_name ?? "Nessun appartamento"
                }`}</Typography>
              </>
            )}
            <Typography
              fontsize={15}
              variant="subtitle2"
              /*  align="center" */
              sx={{
                color:
                  msg.status !== "ok" &&
                  msg.status !== "key_not_valid" &&
                  msg.status !== "digital_twin_name_not_valid" &&
                  theme.palette.success.main,
                /* : theme.palette.warning.main, */
              }}
            >{`Classe del contatore : ${nameTransform(msg.class)}`}</Typography>
            <Divider variant="horizontal" />
          </Grid>
        );
    }
  };
  useEffect(() => {
    setImportData(sortedImportInfo?.map((item) => messageMapper(item)));
  }, [importInfo]);
  useEffect(() => {
    console.log("blablaxa", data);
  }, [data]);

  useEffect(() => {
    if (importInfo) {
      setCreatedCondominiumId(importInfo?.filter((item) => item?.id)?.[0]?.id);
    }
  }, [importInfo]);

  useEffect(() => {
    if (stopSuccess) {
      dispatch(setMessage(t("stop_import")));
      setUuid(uuidv4());
    }
  }, [stopSuccess]);

  const closeModal = () => {
    setModal(false);
    dispatch(clearCondominiumImportInfo());
    dispatch(actions.leaveChannel("general_info"));
    if (data?.update) {
      dispatch(actions.cleanData("update"));
    }

    dispatch(loadingOff());
    setUploadPhase(1);
    dispatch(actions.cashLean());
    setFirstUpload(false);
    clearFile();
    stopImport(uuid);
    setReady(false);
  };
  const uploadFile = () => {
    formData.append("file", files);
    formData.append("operation_id", uuid);

    fetch(`${window.BASE_URL}/api/v1/plugin/condominium_upload`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          console.log("Успешный ответ без содержимого (204)");

          return;
        } else if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          console.log("Успешный ответ:", data);
        }
      })
      .catch((err) => {
        console.error("Произошла ошибка при выполнении запроса:", err);
        dispatch(loadingOff());
        dispatch(setSeverity("error"));
        dispatch(
          setErrors({
            error: `Upload non è andato a buon fine. Contatta l'amministratore di sistema per ulteriore assistenza.`,
          })
        );
      });
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log("smf", status, meta, file);

    setFiles(file);
    setConnect(true);
    if (!firstUpload) {
      dispatch(
        actions.joinChannel({
          name: "general_info",
          params: { uuid: uuid },
        })
      );
    }
  };

  const handleSubmit = (files, allFiles) => {
    console.log(files?.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };
  useEffect(() => {
    if (files && uuid) {
      formData.append("file", files);
      formData.append("operation_id", uuid);
      console.log("sisyapisya", formData);
    }
  }, [files]);
  /*   useEffect(() =>{
    console.log()
  }) */
  /*   useEffect(() => {
    if (operationStatus === "ok") {
      getErrors(operationId);
      getStatistic(operationId);
    }
  }, [operationStatus]); */
  /*   useEffect(() => {
    if (errorsSuccess && statisticSuccess) {
      navigate("/import/show");
    }
  }, [errorsSuccess, statisticSuccess]); */
  useEffect(() => {
    console.log("uuid", uuid);
  }, [uuid]);
  /*   useEffect(() => {
    if (
      channels?.general_info?.state === "joined" &&
      formData.get("operation_id") &&
      formData.get("file")
    ) {
     
      uploadFile();
    }
  }, [channels?.general_info?.state, files, formData]); */

  /*   useEffect(() => {
  
    if (formData.get("operation_id") && formData.get("file")) {
      console.log("zalupka", formData.get("operation_id"));
      setFileIsReady(true);
    }
  }, [files, formData.get("operation_id"), formData.get("file")]); */
  useEffect(() => {
    console.log("importData", importData);
  }, [importData]);

  const onAnalyzeClick = () => {
    dispatch(clearCondominiumImportInfo());

    dispatch(loadingOn());
    setMsg("Analisi in corso");
    setModal(true);
    setReady(true);

    uploadFile();
  };

  useEffect(() => {
    setUuid(uuidv4);
  }, []);
  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/templates/cond.xlsx`;
    link.download = "Template importazione condominio.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <ModalDialog
        open={modal}
        close={closeModal}
        title={"Info"}
        cancell={false}
      >
        <Box>
          <Card sx={{ height: 600, overflowY: "auto", mt: 4, width: 500 }}>
            <CardActionArea>
              <CardContent>
                <Grid container>
                  {loading && (
                    <Grid item xs={12}>
                      <Typography align="center" variant="h5">
                        {msg}
                      </Typography>
                    </Grid>
                  )}
                  {importData?.length > 0 && importData}
                  {!loading &&
                    importData?.length == 1 &&
                    endCommunication !== -1 && (
                      <Grid item xs={12}>
                        <Typography align="center" variant="h5">
                          Il formato del file importato non è corretto
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
          {uploadPhase == 1 && (
            <>
              <Button onClick={closeModal} variant="contained" sx={{ mt: 7 }}>
                {t("cancel")}
              </Button>
              <Button
                onClick={() => {
                  confirmImport({ id: uuid, attributes: {} });
                  setMsg("Inserimento in corso");
                  dispatch(loadingOn());
                  setUploadPhase(2);
                }}
                variant="contained"
                disabled={
                  loading ||
                  notValidKey != -1 ||
                  notValidName != -1 ||
                  invalid_name !== -1 ||
                  missing_k !== -1
                }
                sx={{ float: "right", mt: 7 }}
              >
                {t("confirm")}
              </Button>
            </>
          )}
          {uploadPhase == 2 && (
            <Button
              sx={{ float: "right", mt: 7 }}
              onClick={closeModal}
              disabled={loading}
              variant="contained"
            >
              {t("close")}
            </Button>
          )}
          {uploadPhase == 2 && (
            <Button
              sx={{ mt: 7, mr: 2 }}
              onClick={() => {
                dispatch(clearCondominiumImportInfo());
                closeModal();
                navigate(`/condominiums/${createdCondominiumId}`);
              }}
              disabled={loading}
              variant="contained"
            >
              {t("Vai al condominio")}
            </Button>
          )}
        </Box>
      </ModalDialog>
      <Box sx={{ height: 400, width: appBarIsOpen ? 1596 : 1796 }}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h3">
          {t("import_condominium")}
        </Typography>

        <Box display={"flex"}>
          <Box width={"50%"}>
            {files == null && (
              <Dropzone
                /*     getUploadParams={getUploadParams} */

                styles={{ dropzone: { height: 226, overflow: "hidden" } }}
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                inputContent={t("upload_msg")}
              />
            )}
            {files !== null && (
              <Card sx={{ width: "100%", height: 226 }}>
                <CardActionArea>
                  <CardContent>
                    <Box display={"flex"} alignContent={"center"}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: theme.palette.primary.main, mt: 2.4 }}
                      >
                        {`File to upload : ${files?.name}`}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}
            <Button onClick={downloadFile} sx={{ mt: 2 }} variant="contained">
              {t("get_template")}
            </Button>
            <Button
              sx={{ mt: 2, fill: theme.palette.primary.main, float: "right" }}
              variant="contained"
              disabled={!files}
              onClick={onAnalyzeClick}
            >
              {t("analyze")}
            </Button>
            <Button
              sx={{
                mt: 2,
                fill: theme.palette.primary.main,
                mr: 2,
                float: "right",
              }}
              variant="contained"
              disabled={!files}
              onClick={clearFile}
            >
              {t("clear")}
            </Button>
          </Box>
          <Box sx={{ ml: 7, width: "50%" }}>
            <Card sx={{ width: "100%" }}>
              <CardActionArea>
                <CardContent>
                  <Typography variant="subtitle1">
                    Questa pagina è dedicata all'importazione massiva dei
                    condomini. Per eseguire l'importazione, segui questi
                    passaggi:
                  </Typography>
                  <ol>
                    <li>
                      <Typography variant="subtitle2">
                        Seleziona il file necessario in formato XLSX.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Premi il pulsante "Analizza".
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Attendi che i dati vengano analizzati e visualizzati
                        sullo schermo.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Premi il pulsante "Conferma".
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Attendi che i dati vengano nuovamente analizzati e
                        visualizzati sullo schermo.
                      </Typography>
                    </li>
                  </ol>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        </Box>
        {connect && (
          <WsConnector
            channelName={"general_info"}
            channelParams={{ uuid: uuid }}
            setDataFunction={setCondominiumImportInfo}
            hiden={true}
            eventName={"update"}
            manualClean={true}
            /*  setDataFunction={updateDashboardData} */
          />
        )}
      </Box>
    </>
  );
};
export default Uploader;
