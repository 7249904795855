import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import * as React from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import debounce from "lodash.debounce";
const ErrorTypeSelector = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const err = useSelector(
    (state) => state?.errorAssociationSlice?.errorAssociation
  );
  const [page, setPage] = useState(1);
  const errorType = useSelector((state) => state?.errorTypeSlice?.errorType);
  const [value, setValue] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(id ? props?.schema?.default : "");
  const [getErrorTypes] = store.useLazyGetErrorTypesQuery();
  const errorhandler = (e) => {
    setError(e.target?.value);
  };

  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);
  const errorTypes = useSelector(
    (state) => state?.errorTypeSlice?.errorTypes
  )?.map((err) => ({
    label: err?.name,
    value: err?.id,
  }));
  useEffect(() => {
    if (searchValue?.length == 0 || value?.value?.length == 0) {
      getErrorTypes();
    }
  }, [searchValue]);
  useEffect(() => {
    if (value?.value) {
      props.onChange(value?.value);
    }
  }, [value]);
  useEffect(() => {
    if (props?.schema?.update) {
      if (errorType && !ready) {
        const defval = {
          label: errorType?.name,
          value: errorType?.id,
        };
        setValue(defval);
        setReady(true);
      }
    } else {
      setReady(true);
    }
  }, [props, errorType]);
  useEffect(() => {
    console.log("vartuhuek", props);
  }, [props]);
  useEffect(() => {
    console.log("valyamba", value);
  }, [value]);
  useEffect(() => {
    if (searchValue) {
      getErrorTypes({ name: searchValue });
    }
  }, [searchValue]);
  return (
    <>
      {ready && (
        <Autocomplete
          fullWidth
          options={errorTypes ?? []}
          value={value}
          popupIcon={""}
          onChange={(e, newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                onChange={debValue}
                {...params}
                value={value}
                error={props?.rawErrors?.length > 0}
                required={props.required}
                label={t("error_type")}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default ErrorTypeSelector;
