import IwdDataGrid from "../../pages/IwdDataGrid";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { store } from "../../redux/store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Link, useParams } from "react-router-dom";
import ModalDialog from "../meters/ModalDialog";
import InfoIcon from "@mui/icons-material/Info";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import {
  setFromBlock,
  setFromBlockId,
  setRefreshActions,
  setActionPage,
} from "../../redux/slices/actionsSlice";
import { Tooltip } from "@mui/material";
import { setBlockTabName } from "../../redux/slices/condominiumSlice";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ActionsIndex = ({ blockId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getActions] = store.useLazyGetActionsIndexQuery();
  const newReplacedSerial = useSelector(
    (state) => state?.condominiumSlice?.newReplacedSerial
  );
  const createdMeterSerial = useSelector(
    (state) => state?.condominiumSlice.createdMeterSerial
  );
  const refreshActions = useSelector(
    (state) => state?.actionsSlice?.refreshActions
  );
  const blockTabName = useSelector(
    (state) => state?.condominiumSlice?.blockTabName
  );
  const condominiumId = useSelector(
    (state) => state?.condominiumSlice?.condominiumId
  );
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const desckLink = IwdFormAuthWrapper({ section: "replace_desc_link" });
  const page = useSelector((state) => state?.actionsSlice?.page);
  const [perPage] = useState(10);
  const [formData, setFormData] = useState({});
  const actions = useSelector((state) => state?.actionsSlice?.actions);
  const actionsCount = useSelector((state) => state?.actionsSlice?.count);
  const options = [
    { label: t("create_device"), value: "create_device" },
    { label: t("create_appartment"), value: "create_appartment" },
  ];
  const stateOptions = [
    { label: t("approved"), value: "true" },
    { label: t("to_approve"), value: "false" },
  ];
  useEffect(() => {
    getActions({
      approved: "false",
      page: page,
      per_page: perPage,
      root_id: blockId,
      ...formData,
    });
  }, [page]);

  useEffect(() => {
    if (refreshActions) {
      getActions({
        approved: "false",
        page: page,
        per_page: perPage,
        root_id: blockId,
        ...formData,
      });
      dispatch(setRefreshActions(false));
    }
  }, [refreshActions]);

  useEffect(() => {
    if (blockTabName == "block_approve") {
      getActions({
        approved: "false",
        page: page,
        per_page: perPage,
        root_id: blockId,
        ...formData,
      });
    }
  }, [blockTabName]);
  const onSubmitClick = (obj) => {
    dispatch(setActionPage(1));
    getActions({
      page: 1,
      per_page: perPage,
      root_id: blockId,
      ...obj,
    });
  };
  useEffect(() => {
    dispatch(setBlockTabName(null));
  }, []);
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      approved: {
        id: 1,
        title: t("state"),
        type: "string",
        oneOf: stateOptions.map((option) => ({
          const: option.value,
          title: option.label,
        })),
        default: "false",
      },
      /*  action_type: {
        id: 1,
        title: t("action_type"),
        type: "string",
        oneOf: options.map((option) => ({
          const: option.value,
          title: option.label,
        })),
      }, */
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
  };
  const onInfoClick = (msg) => {
    setModal(true);
    setMessage(msg);
  };

  const blockNavFn = (actionId) => {
    debugger;
    navigate(`/actions/${actionId}`);
    dispatch(setFromBlock(true));
    dispatch(setFromBlockId(id));
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "action_type",
      headerName: t("action_type"),
      type: "number",
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        if (params?.value == "create_apartment") {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_apartment")}>
                <ApartmentIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
        if (
          params?.value == "create_device" ||
          params?.value == "create_and_associate_device"
        ) {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_device")}>
                <GasMeterIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
        if (params?.value == "create_and_replace_device") {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_and_replace_device")}>
                <PublishedWithChangesIcon
                  sx={{ color: theme.palette.primary.main }}
                />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
      },
    },

    {
      field: "condominium",
      headerName: t("condominium"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        if (params?.value?.id) {
          return (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width="60%"
            >
              <Typography sx={{ marginRight: 2 }}>
                {params?.value?.name}
              </Typography>
              <IconButton>
                <Link to={`/condominiums/${params?.value.id}`} target="blank">
                  <VisibilityIcon
                    sx={{ fill: theme.palette.primary.main, mt: 1 }}
                  />
                </Link>
              </IconButton>
            </Box>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "subcondominium",
      headerName: t("subcondominum"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "created_by",
      headerName: t("created_by"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
      renderCell: (params) => {
        const user = params?.value?.user;
        const info = params?.value?.info;
        return (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width="60%"
          >
            <Typography sx={{ marginRight: 2 }}>
              {typeof user === "string" ? user : ""}
            </Typography>
            {info && (
              <IconButton onClick={() => onInfoClick(info)}>
                <Tooltip title={t("user_info")}>
                  <PersonSearchIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: t("description"),
      width: 90,
      sortable: false,
      flex: 3,

      renderCell: (params) => {
        if (params?.value?.attributes.action_type == "create_apartment") {
          return (
            <Typography>{`${t("created_new_apartment")}: ${
              params?.value?.attributes?.meta_data?.create_params?.name ??
              params?.value?.attributes?.meta_data?.params?.name
            }`}</Typography>
          );
        }
        if (
          params?.value?.attributes.action_type == "create_device" ||
          params?.value?.attributes.action_type == "create_and_associate_device"
        ) {
          return (
            <Typography>{`${t("created_new_device")}:  ${
              params?.value?.attributes?.meta_data?.params?.data?.attributes
                ?.fields?.serial
            }`}</Typography>
          );
        }
        if (
          params?.value?.attributes.action_type == "create_and_replace_device"
        ) {
          return (
            <Typography>
              <span
                style={{
                  textDecoration: desckLink && "underline",
                  cursor: desckLink && "pointer",
                }}
                onClick={() =>
                  desckLink &&
                  window.open(
                    `/meters/${params?.value?.attributes?.meta_data?.params?.data?.attributes?.old_device_id}`,
                    "_blank"
                  )
                }
              >
                {`${t("old_device")}: ${
                  params?.value?.attributes?.meta_data?.params?.data?.attributes
                    ?.old_device_serial
                }`}
              </span>
              {` ${t("replaced_with_new_device")} ${
                params?.value?.attributes?.meta_data?.params?.data?.attributes
                  ?.fields?.serial
              }`}
            </Typography>
          );
        }
      },
    },
    {
      field: "approved_at",
      headerName: t("approved_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "disapproved_at",
      headerName: t("disapproved_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <Link to={`/actions/${params?.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </Box>
          );
        }
      },
    },
  ];
  const fromBlockcolumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "action_type",
      headerName: t("action_type"),
      type: "number",
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        if (params?.value == "create_apartment") {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_apartment")}>
                <ApartmentIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
        if (
          params?.value == "create_device" ||
          params?.value == "create_and_associate_device"
        ) {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_device")}>
                <GasMeterIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
        if (params?.value == "create_and_replace_device") {
          return (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
            >
              <Tooltip title={t("create_and_replace_device")}>
                <PublishedWithChangesIcon
                  sx={{ color: theme.palette.primary.main }}
                />
              </Tooltip>
              <Typography sx={{ ml: 1 }}>{t(params?.value)}</Typography>
            </Box>
          );
        }
      },
    },

    {
      field: "subcondominium",
      headerName: t("subcondominum"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "created_by",
      headerName: t("created_by"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 2,
      renderCell: (params) => {
        const user = params?.value?.user;
        const info = params?.value?.info;
        return (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width="60%" // Обеспечивает, что Box занимает всю доступную ширину
          >
            <Typography sx={{ marginRight: 2 }}>
              {typeof user === "string" ? user : ""}
            </Typography>
            {info && (
              <IconButton onClick={() => onInfoClick(info)}>
                <Tooltip title={t("user_info")}>
                  <PersonSearchIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: t("description"),
      width: 90,
      sortable: false,
      flex: 3,

      renderCell: (params) => {
        if (params?.value?.attributes.action_type == "create_apartment") {
          return (
            <Typography>{`${t("created_new_apartment")}: ${
              params?.value?.attributes?.meta_data?.create_params?.name ??
              params?.value?.attributes?.meta_data?.params?.name
            }`}</Typography>
          );
        }
        if (
          params?.value?.attributes.action_type == "create_device" ||
          params?.value?.attributes.action_type == "create_and_associate_device"
        ) {
          return (
            <Typography>{`${t("created_new_device")}:  ${
              params?.value?.attributes?.meta_data?.params?.data?.attributes
                ?.fields?.serial
            }`}</Typography>
          );
        }
        if (
          params?.value?.attributes.action_type == "create_and_replace_device"
        ) {
          return (
            <Typography>
              <span
                style={{
                  textDecoration: desckLink && "underline",
                  cursor: desckLink && "pointer",
                }}
                onClick={() =>
                  desckLink &&
                  window.open(
                    `/meters/${params?.value?.attributes?.meta_data?.params?.data?.attributes?.old_device_id}`,
                    "_blank"
                  )
                }
              >
                {`${t("old_device")}: ${
                  params?.value?.attributes?.meta_data?.params?.data?.attributes
                    ?.old_device_serial
                }`}
              </span>
              {` ${t("replaced_with_new_device")} ${
                params?.value?.attributes?.meta_data?.params?.data?.attributes
                  ?.fields?.serial
              }`}
            </Typography>
          );
        }
      },
    },
    {
      field: "approved_at",
      headerName: t("approved_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "disapproved_at",
      headerName: t("disapproved_at"),
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params?.value) {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <IconButton onClick={() => blockNavFn(params?.value)}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];
  const closeModal = () => {
    setModal(false);
    setMessage(null);
  };
  const rows = actions?.map((item, i) => ({
    id: item?.attributes?.id,
    action_type: item?.attributes?.action_type,
    condominium: {
      name:
        item?.attributes?.meta_data?.condominium_name ??
        item?.attributes?.meta_data?.params?.data?.attributes?.fields
          ?.condominium_name,
      id:
        item?.attributes?.meta_data?.condominium_id ??
        item?.attributes?.meta_data?.params?.data?.attributes?.fields
          ?.condominium_id,
    },
    subcondominium:
      item?.attributes?.meta_data?.params?.data?.attributes?.fields
        ?.sub_condominium_name ??
      item?.attributes?.meta_data?.params?.subcondominium_name,
    apartment:
      item?.attributes?.meta_data?.params?.data?.attributes?.fields
        ?.apartment_name,
    created_by: {
      user: `${
        item?.attributes?.meta_data?.user?.first_name ??
        item?.attributes?.meta_data?.current_user?.first_name ??
        ""
      } ${
        item?.attributes?.meta_data?.user?.last_name ??
        item?.attributes?.meta_data?.current_user?.last_name ??
        ""
      }`,
      info:
        item?.attributes?.meta_data?.user ??
        item?.attributes?.meta_data?.current_user,
    },

    description: item,
    approved_at: item?.attributes?.approved_at
      ? t("dt", {
          val: new Date(item?.attributes?.approved_at),
        })
      : "--",
    disapproved_at: item?.attributes?.not_approved_at
      ? t("dt", {
          val: new Date(item?.attributes?.not_approved_at),
        })
      : "--",
    actions: item?.attributes?.id,
  }));
  const setPage = (page) => {
    dispatch(setActionPage(page));
  };
  return (
    <>
      <ModalDialog open={modal} close={closeModal} title={"Info"}>
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",

                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h2"
      >
        {t("actions")}
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {t("search_form")}
        </AccordionSummary>
        <AccordionDetails>
          <Form
            schema={schema}
            formData={formData}
            showErrorList={false}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => {
              onSubmitClick(values.formData);
            }}
            validator={validator}
            uiSchema={uiSchema}
          />
        </AccordionDetails>
      </Accordion>

      <IwdDataGrid
        columns={blockId ? fromBlockcolumns : columns}
        perPage={perPage}
        setPage={setPage}
        rows={rows}
        page={page}
        count={actionsCount}
        sort={false}
        height={600}
        paginationMode={"server"}
      />
    </>
  );
};

export default ActionsIndex;
