import * as React from "react";
import Popover from "@mui/material/Popover";

import ErrorIcon from "@mui/icons-material/Error";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const PopoverCardTitle = ({ title, description, edit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOver = () => {
    if (!edit) {
      setOpen(!open);
    }
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ p: 1, cursor: "pointer" }} onClick={handleClick}>
      <Typography
        onClick={handlePopOver}
        sx={{
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        fontSize={16}
        color={theme.palette.primary.main}
        inputProps={{
          maxlength: 2,
        }}
      >
        {title}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: 3,
            gap: 2,
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>
              <>
                <b>Title: </b>
                {`${title}`}
              </>
            </Typography>

            <CloseIcon onClick={handlePopOver} />
          </Box>

          {/*  <Typography>{`Description: ${description}`}</Typography> */}
          <Typography>
            <>
              <b>Description: </b> {`${description}`}
            </>
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default PopoverCardTitle;
