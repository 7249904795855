import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/it";

const MeterDatePicker = (props) => {
  const defaultStartDate = props?.schema?.properties?.from?.default;
  const defaultEndtDate = props?.schema?.properties?.to?.default;
  const [date, setDate] = useState({ ...props.formData });
  const [startDate, setStartDate] = useState(
    defaultStartDate ? defaultStartDate : null
  );
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [endDate, setEndDate] = useState(
    defaultEndtDate ? defaultEndtDate : null
  );
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];
  let StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];
  const newFrom = new Date(startDate);
  const newTo = new Date(endDate);
  const invalidStartDate = newFrom == "Invalid Date" ? "Invalid Date" : null;
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;
  useEffect(() => {
    console.log("fromdtwid", props);
    if (props?.formData?.from == null && props?.formData?.to == null) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [props]);

  useEffect(() => {
    console.log("enda", newTo);
  }, [endDate]);
  useEffect(() => {
    if (newTo == "Invalid Date") {
      props.onChange({
        to: null,
      });
    }
    if (newFrom == "Invalid Date") {
      props.onChange({
        from: null,
      });
    }
  }, [newTo, newFrom]);
  useEffect(() => {
    if (newTo != "Invalid Date" && endDate !== null) {
      const end = new Date(endDate);
      const to = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
      );

      props.onChange({
        from: props?.formData?.from,
        to: to ? to.toISOString() : null,
      });
    }
  }, [endDate]);
  useEffect(() => {
    if (newFrom != "Invalid Date" && startDate !== null) {
      const start = new Date(startDate);
      const from = new Date(
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
      );

      props.onChange({
        from: from ? from.toISOString() : null,
        to: props?.formData?.to,
      });
    }
  }, [startDate]);
  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);
      setStartDate(null);
      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);
  useEffect(() => {
    console.log("datePROPS", props);
  }, [props]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
                helperText={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
              />
            )}
            label={t("start_date")}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%", ml: 1 }}
                {...props}
                error={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
                helperText={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
              />
            )}
            label={t("end_date")}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default MeterDatePicker;
