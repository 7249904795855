import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import IwdTable, { useIwdTable } from "./../../components/IwdTable";
import { Button, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeviceDistrict } from "./../DeviceDistrictProvider";
import DeleteDialog from "../DeleteDialog";
import IwdDateTime from "../IwdDateTime";

function DeviceDistrictTable() {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const { page, perPage, pages, onChangePage, onPageNext, onPagePrev } =
    useIwdTable();

  const { districts, searchWithPage, loading, error, remove } =
    useDeviceDistrict();

  const columns = [
    {
      name: t("id"),
      selector: (row) => row.id,
    },
    {
      name: t("name"),
      selector: (row) => row.attributes.name,
    },
    {
      name: t("description"),
      selector: (row) => row.attributes.description,
    },
    {
      name: t("inserted_at"),
      selector: (row) => t("dt", { val: new Date(row.attributes.inserted_at) }),
    },
    {
      name: t("actions"),
      selector: (row) => (
        <Stack direction="row">
          <Button as={Link} to={`/districts/${row.id}`}>
            <VisibilityIcon />
          </Button>
          <Button
            onClick={() => {
              setDeleteId(row.id);
              setShowDelete(true);
            }}
            color="error"
          >
            <DeleteIcon />
          </Button>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (page && perPage) {
      searchWithPage(page, perPage);
    }
  }, [page, perPage]);

  if (loading || districts == null) {
    return <Loader />;
  }

  if (error) {
  }

  return (
    <div>
      {showDelete ? (
        <DeleteDialog
          setShow={setShowDelete}
          show={showDelete}
          deleteId={deleteId}
          deleteFunction={remove}
        />
      ) : (
        ""
      )}
      <IwdTable
        columns={columns}
        loading={loading}
        data={districts}
        onPagePrev={onPagePrev}
        onPageNext={onPageNext}
        onChangePage={onChangePage}
        page={page}
        pages={pages}
        count={districts.meta.count}
        perPage={perPage}
      />
    </div>
  );
}

export default DeviceDistrictTable;
