import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { useIwdBreadCrumb } from "./IwdBreadcrumbProvider";

const RenderElements = (t, elements, last) => {
  const navigate = useNavigate();
  const meterShowValue = useSelector(
    (state) => state?.breadCrumbMeterSlice?.identifierTypeValue
  );
  const userName = useSelector((state) => state?.breadCrumbMeterSlice?.user);
  const blockName = useSelector(
    (state) => state?.breadCrumbMeterSlice?.blockName
  );
  const gwSerial = useSelector(
    (state) => state?.breadCrumbMeterSlice?.gatewaySerial
  );

  const escludedStrings = ["apartamentMeter", "apartments", "subcondominiums"];
  const originalBreadcrumbs = elements.split("/");
  const breadcrumbs = elements
    .split("/")
    ?.map((item) => decodeURIComponent(item));

  const decodedBreadcrumbs = breadcrumbs?.map((item) => {
    return t(decodeURIComponent(item));
  });

  const lastItem = decodedBreadcrumbs.pop();

  useEffect(() => {
    console.log("Decoded breadcrumbs:", breadcrumbs);
  }, [breadcrumbs]);

  const results = decodedBreadcrumbs.reduce(
    ([base, items], currentValue, currentIndex) => {
      base.push(originalBreadcrumbs[currentIndex]);
      if (originalBreadcrumbs[currentIndex] === "") {
        items.push(
          <IconButton key="home" onClick={() => navigate("/")}>
            <HomeIcon />
          </IconButton>
        );
      } else {
        const isExcluded = escludedStrings.includes(
          originalBreadcrumbs[currentIndex]
        );
        items.push(
          isExcluded ? (
            <Typography key={currentIndex} color="text.primary">
              {currentValue}
            </Typography>
          ) : (
            <Link
              key={currentIndex}
              underline="hover"
              color="inherit"
              href={base.join("/")}
            >
              {currentValue}
            </Link>
          )
        );
      }
      return [base, items];
    },
    [[], []]
  );

  if (parseInt(lastItem)) {
    results[1].push(
      <Typography key={breadcrumbs.length} color="text.primary">
        {meterShowValue ?? userName ?? blockName ?? gwSerial ?? lastItem}
      </Typography>
    );
  } else {
    results[1].push(
      <Typography key={breadcrumbs.length} color="text.primary">
        {decodeURIComponent(lastItem)}
      </Typography>
    );
  }

  return results[1];
};

const IwdBreadCrumb = () => {
  const { t } = useTranslation();
  const { last } = useIwdBreadCrumb();
  const location = useLocation();
  const meterShowValue = useSelector(
    (state) => state?.breadCrumbMeterSlice?.identifierTypeValue
  );
  const userName = useSelector((state) => state?.breadCrumbMeterSlice?.user);
  const results = RenderElements(
    t,
    location.pathname,
    last,
    meterShowValue,
    userName
  );

  useEffect(() => {}, [meterShowValue]);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Paper sx={{ padding: 2 }}>
        {results?.[0]?.props?.children !== "logged" && (
          <Breadcrumbs aria-label="breadcrumb">
            {results?.map((r, index) => (
              <span key={index}>{r}</span>
            ))}
          </Breadcrumbs>
        )}
      </Paper>
    </Box>
  );
};

export default IwdBreadCrumb;
