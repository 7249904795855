import React, { useEffect, useState } from "react";
import { Polygon, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "@bopen/leaflet-area-selection/dist/index.css";
import { useTranslation } from "react-i18next";
import { DrawAreaSelection } from "@bopen/leaflet-area-selection";
import { Box } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "../../theme";
import { Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { worklistActions as actions } from "../../redux/slices/worklistSlice";
import DeviceSvg from "../../statics/icons/device.svg";
import ReactDOMServer from "react-dom/server";
import DeviceIcon from "../DeviceIcon";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { polygon } from "@turf/turf";

const defaultLocation = [45.40667650588728, 11.879199098463717];

const WorkListEditorMapComponent = ({ setDrawPolygon, setCurrentPolygon }) => {
  const [polygonCenter, setPolygoncenter] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [devs, setDevs] = useState([]);
  const drawPolygon = useSelector((state) => state?.worklistSlice?.drawPolygon);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );

  const polygons = useSelector((state) => state?.worklistSlice?.polygons);
  const polygonCoordinates = useSelector(
    (state) => state?.worklistSlice?.polygonCoordinates
  );
  const polygonName = useSelector((state) => state?.worklistSlice?.polygonName);
  const newPolygonsInUpdate = useSelector(
    (state) => state?.worklistSlice?.newPolygonsInUpdate
  );
  const devices = useSelector((state) => state?.worklistSlice?.devices);
  const [location, setLocation] = useState(defaultLocation);
  const map = useMap();

  useEffect(() => {
    if (devices) {
      setDevs(Object.values(devices).flat());
    }
  }, [devices]);

  useEffect(() => {
    if (polygonCoordinates) {
      map.flyTo(polygonCoordinates);
      dispatch(actions.setPoligonCoordinates(null));
    }
  }, [polygonCoordinates]);
  const deviceIcon = (color) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<DeviceIcon />),
      className: "",
      iconSize: [25, 25],
      iconAnchor: [10, 10],
    });

  const onPolygonReady = (polygon) => {
    setCurrentPolygon(polygon);
  };
  let areaSelection;
  useEffect(() => {
    if (map && !drawPolygon && !areaSelection) {
      areaSelection = new DrawAreaSelection(
        {
          onPolygonReady: onPolygonReady,
        },
        false
      );
      map.addControl(areaSelection);
      setDrawPolygon(areaSelection);
    }
  }, [map, drawPolygon]);
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }, [appBarIsOpen]);

  const markerComponents = devs?.map((device) => {
    return (
      <Marker
        icon={deviceIcon()}
        key={device.id}
        position={[
          device.attributes.coordinates.coordinates[1],
          device.attributes.coordinates.coordinates[0],
        ]}
      >
        <Popup>
          <Box style={{ display: "flex" }}>
            <Typography>{`Serial number:  ${device?.attributes?.serial_number}`}</Typography>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => navigate(`/meters/${device?.id}`)}
            >
              <Tooltip title="go to device">
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Tooltip>
            </IconButton>
          </Box>
        </Popup>
      </Marker>
    );
  });
  useEffect(() => {
    console.log("devvvvs", markerComponents);
  }, [markerComponents]);
  useEffect(() => {
    console.log("devices", devs);
  }, [devs]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {markerComponents?.length > 0 && markerComponents}

      {polygons?.map(({ pathOptions, positions }) => {
        return (
          <Polygon pathOptions={{ ...pathOptions }} positions={positions} />
        );
      })}
      {newPolygonsInUpdate?.map(({ pathOptions, positions }) => {
        return (
          <Polygon pathOptions={{ ...pathOptions }} positions={positions} />
        );
      })}
    </>
  );
};

export default React.memo(WorkListEditorMapComponent);
