import { useEffect } from "react";

const usePreviousPath = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("previousPath", window.location.pathname);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return sessionStorage.getItem("previousPath");
};

export default usePreviousPath;
