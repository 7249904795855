import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { store } from "../../../redux/store";

import { setDigitalTwin } from "../../../redux/slices/digitalTwinSlice";

import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { setTransofrmName } from "../../../redux/slices/meterSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import { setSelectedMeter } from "../../../redux/slices/meterSlice";
import { Diamond } from "@mui/icons-material";
import { setLoraAreas } from "../../../redux/slices/gatewaySlice";
const timeZoneDefaultValue = [
  { title: "The Shawshank Redemption", year: 1994 },
];

const SearcheableSelect = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedMeterId, setSelectedmeterId] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [getAttributes] = store.useLazyGetMeterAttributesQuery();
  const transformName = useSelector(
    (state) => state?.meterSlice?.transformName
  );
  const [value, setValue] = useState(props.formData ? props.formData : "");
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataQuery();
  const diameter = props?.schema?.dispatched
    ? result?.data?.data?.filter((item) => item?.attributes?.id == value?.key)
    : null;
  let data = result ? props?.schema?.mapFn(result) : [];
  /*   let tranformNameData = result ? props?.schema?.trasformFn(result) : []; */

  const selectedMeter = props?.schema?.dispatched
    ? result?.data?.data?.filter((item) => item?.attributes?.id == value?.key)
    : null;
  useEffect(() => {
    if (value && value != "" && value != props.formData) {
      props.onChange(value.value);
    }
  }, [value]);
  useEffect(() => {
    console.log("суктщргш", props);
  }, [props]);

  /*   useEffect(() => {
    nameTransform("Maddalena Gradus - EFE:13:08 (1.0.1)");
  }, []); */
  /*  useEffect(() => {
    if (transformName && result) {
    
      let filteredMeters = result?.data?.data?.filter
(
(item) =>
        item?.attributes?.digital_twin_fields?.tags?.includes("WmBus")
      );
      let otherMeters = result?.data?.data?.filter
(

        (item) =>
          !item?.attributes?.digital_twin_fields?.tags?.includes("WmBus")
      );
      console.log("meterfilter", filteredMeters);
      console.log("meterother", otherMeters);
    }
  }, [transformName, result]); */
  /*   useEffect(() => {
    if (transformName && result) {
      data.map;
    }
  }, [transformName, result]); */
  useEffect(() => {
    console.log("pezda", value);
  }, [value]);
  useEffect(() => {
    if (props?.schema?.dispatched) {
      dispatch(setDigitalTwin(diameter));
    }
  }, [value]);
  useEffect(() => {
    if (props?.schema?.lora && value) {
      const selectedLora = result?.data?.data?.filter(
        (item) => item?.id == value.key
      );
      console.log("lariska", selectedLora);
      dispatch(
        setLoraAreas({
          areas: selectedLora?.[0]?.attributes?.fields?.areas,
          id: value?.key,
          profiles: selectedLora?.[0]?.attributes?.fields?.profiles,
        })
      );
    }
  }, [value]);
  useEffect(() => {
    if (props?.schema?.dispatched) {
      dispatch(setSelectedMeter(selectedMeter));
    }
  }, [selectedMeter]);
  useEffect(() => {
    if (selectedMeter?.length > 0) {
      setSelectedmeterId(selectedMeter[0].id);
    }
  }, [selectedMeter]);
  useEffect(() => {
    if (selectedMeterId) {
      getAttributes(selectedMeterId);
    }
  }, [selectedMeterId]);
  useEffect(() => {
    if (open && firstRender) {
      Promise.all([trigger(props.schema.url)]).then(() => {
        setLoading(false);
        setFirstRender(false);
      });
    }
  }, [open]);
  useEffect(() => {
    console.log("rezik", data);
  }, [data]);

  const openFunc = () => {
    setOpen(true);
    if (firstRender) {
      setLoading(true);
    }
  };
  const dataSelector = () => {
    if (data) {
      return data;
    } else {
      return [];
    }
  };
  return (
    <>
      <Autocomplete
        fullWidth
        value={props?.formData && props?.formData}
        open={open}
        onOpen={openFunc}
        onClose={() => setOpen(false)}
        options={data ? data : []}
        disableClearable
        defaultValue={props?.schema?.defaultTimeZone && "Europe/Rome"}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <Box display={"flex"} alignItems={"center"}>
              <TextField
                required={props.required}
                {...params}
                error={props?.rawErrors?.length > 0}
                label={props.schema.title}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {props?.schema?.checkbox && (
                <Tooltip>
                  <FormControlLabel
                    required
                    labelPlacement="bottom"
                    control={
                      <Checkbox onClick={() => dispatch(setTransofrmName())} />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          );
        }}
      />
    </>
  );
};

export default SearcheableSelect;
