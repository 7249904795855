import ImgUpload from "./ImgUpload";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import ImageBox from "./ImgBox";
import { useState, useEffect } from "react";
const SwarmSettings = () => {
  const [setConfiguration, { isSuccess }] = store.useSetConfigurationMutation();
  const [getConfig, { isSuccess: configSuccess }] =
    store.useLazyGetShowConfigurationQuery();
  const favid = useSelector((state) => state?.configurationSlice?.favId);
  const logoId = useSelector((state) => state?.configurationSlice?.logoId);
  const [newFavId, setFavId] = useState(null);
  const [newLogoId, setLogoId] = useState(null);
  const onSaveClick = () => {
    const attributes = {
      favicon_id: newFavId ? +newFavId : +favid,
      logo_id: newLogoId ? +newLogoId : +logoId,
    };
    setConfiguration(attributes);
  };
  useEffect(() => {
    if (isSuccess) {
      getConfig();
    }
  }, [isSuccess]);
  return (
    <>
      <ImageBox
        success={configSuccess}
        msg={"Logo is successfully uploaded"}
        seveIdFn={setLogoId}
        imgId={logoId}
        text={"Swarm logo"}
      />
      <Box sx={{ mt: 2 }}>
        <ImageBox
          success={configSuccess}
          seveIdFn={setFavId}
          imgId={favid}
          msg={"Fav icon is successfully uploaded"}
          text={"Swarm fav icon"}
        />
      </Box>
      <Button
        onClick={onSaveClick}
        sx={{ mt: 2, float: "right" }}
        variant="contained"
      >
        Save
      </Button>
    </>
  );
};

export default SwarmSettings;
