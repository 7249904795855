import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import theme from "../../theme";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";

import { setBlockTrashId } from "../../redux/slices/userGroupSlice";
import { CircularProgress, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import IwdDataGrid from "../../pages/IwdDataGrid";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useDispatch, useSelector } from "react-redux";

const BlockGroupAutocomlete = ({ groupId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getCondominumList] = store.useLazyGetGroupBlocksQuery();
  const condominumList = useSelector(
    (state) => state?.userSlice?.condominumList
  );
  const [disasocBlock, { isSuccess: disasocSuccess }] =
    store.useDisasocBlockFromGroupMutation();

  const blocks = useSelector((state) => state?.condominiumSlice?.condominiums);
  const [trashModal, setTrashModal] = useState(false);
  const associatedBlocks = useSelector((state) => state?.userGroupSlice?.block);
  const count = useSelector((state) => state?.userGroupSlice?.blocCount);
  const blockTrashId = useSelector(
    (state) => state?.userGroupSlice?.blockTrashId
  );
  const options = blocks?.map((item) => ({
    title: item?.attributes?.fields?.name,
    value: item?.id,
  }));

  const [getCondominiums] = store.useLazyCondominiumIndexQuery();
  const [assocBlockToGroup, { isSuccess }] =
    store.useAssocBlockToGroupMutation();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [trashId, setTrashId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);

  const debValue = debounce((e) => {
    console.log("debik");
    setSearchValue(e.target.value);
  }, 500);
  useEffect(() => {
    if (value && searchValue) {
      setSearchValue("");
      getCondominiums({ page: 1, per_page: 10 });
    }
  }, [value]);
  useEffect(() => {
    getCondominumList({
      id: groupId,
      params: {
        with_devices: true,
        page: page,
        per_page: perPage,
      },
    });
  }, [page, isSuccess]);
  useEffect(() => {
    getCondominiums({ page: 1, per_page: 10 });
  }, []);
  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };
  useEffect(() => {
    console.log("condix", options);
  }, [options]);
  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getCondominiums({ name: searchValue }).then(() => {
        setLoading(false);
      });
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("searchValue", searchValue);
  }, [searchValue]);
  useEffect(() => {
    console.log("valiuxa", value);
  }, [value]);

  const onAssocClick = () => {
    const attributes = {
      user_group_id: groupId,
      device_id: value?.value,
    };
    assocBlockToGroup({
      id: groupId,
      attributes: attributes,
    });
  };
  useEffect(() => {
    if (blockTrashId) {
      disasocBlock({
        groupID: groupId,
        blockId: blockTrashId,
      });
      dispatch(setBlockTrashId(null));
    }
  }, [blockTrashId]);

  useEffect(() => {
    if (disasocSuccess) {
      dispatch(setMessage(t("dissoc_succ")));
      getCondominumList({
        id: groupId,
        params: {
          with_devices: true,
          page: page,
          per_page: perPage,
        },
      });
    }
  }, [disasocSuccess]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "block",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper
              children={
                <IconButton
                  onClick={() => onTrashClick(params?.value?.deleteId)}
                  sx={{ float: "right" }}
                >
                  <DeleteIcon sx={{ ml: 2, fill: theme.palette.error.main }} />
                </IconButton>
              }
              section={"delete_meter"}
            />
            <Link to={`/condominiums/${params.value?.id}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const rows = associatedBlocks?.map((item, i) => ({
    id: i,
    block: item?.attributes?.fields?.name,
    actions: { id: item?.id, deleteId: item?.deleteId },
  }));
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        title={""}
        text={t("disasoc_block_text")}
        deleteFunc={() => setBlockTrashId(trashId)}
      />
      <Autocomplete
        value={value}
        id="multiple-limit-tags"
        options={options ?? []}
        getOptionLabel={(option) => option?.title ?? ""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <>
              <TextField
                onChange={debValue}
                onClick={() => console.log("huivpopeblin")}
                {...params}
                value={value}
                /*     error={props?.rawErrors?.length > 0}
                required={props.required}
                label={props.schema.title} */
                label={t("search_block")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={onAssocClick}
                variant="contained"
                sx={{ mt: 2, mb: 2, float: "right" }}
              >
                {t("assoc")}
              </Button>
            </>
          );
        }}
      />
      <Box sx={{ mt: 10 }}>
        <IwdDataGrid
          columns={columns}
          perPage={perPage}
          setPage={setPage}
          rows={rows}
          page={page}
          count={count}
          sort={false}
          height={600}
          paginationMode={"server"}
        />
      </Box>
    </>
  );
};
export default BlockGroupAutocomlete;
