import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DatePicker = (props) => {
  const defaultStartDate = props?.schema?.properties?.from?.default;
  const defaultEndtDate = props?.schema?.properties?.to?.default;
  const [date, setDate] = useState({ ...props.formData });
  const [startDate, setStartDate] = useState(
    defaultStartDate ? defaultStartDate : null
  );
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [endDate, setEndDate] = useState(
    defaultEndtDate ? defaultEndtDate : null
  );
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];
  let StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];
  const newFrom = new Date(startDate);
  const newTo = new Date(endDate);
  const invalidStartDate = newFrom == "Invalid Date" ? "Invalid Date" : null;
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;
  useEffect(() => {
    console.log("propsyara", props.formData);
    if (props?.formData?.from == null && props?.formData?.to == null) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [props]);
  useEffect(() => {
    if (newFrom != "Invalid Date" && newTo != "Invalid Date") {
      props.onChange({
        from: startDate ? new Date(startDate).toISOString() : null,
        to: endDate ? new Date(endDate).toISOString() : null,
      });
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);
      setStartDate(null);
      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);
  useEffect(() => {
    console.log("datePROPS", props);
  }, [props]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={6}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
                helperText={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
              />
            )}
            label={t("start_date")}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ ml: 2 }}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "100%" }}
                {...props}
                error={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
                helperText={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
              />
            )}
            label={t("end_date")}
            value={endDate}
            onChange={(newValue) => {
              /*   setDate((prev) => ({
                ...prev,
                end: new Date(newValue.toString()),
              })); */
              setEndDate(newValue);
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default DatePicker;
