import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontSize: 50,
      fontWeight: 900,
    },
    button: {
      color: "red", // Это настройка, которая не влияет на компонент MuiButton
    },
  },
  palette: {
    type: "light",
    primary: {
      main: window?.THEME?.primary,
    },
    secondary: {
      main: window?.THEME?.secondary,
    },
    version: window.THEME.version,
    footer: window.THEME.footer,
    error: {
      main: "#bd1e1e",
    },
    warning: {
      main: "#f6ae2d",
    },
    anomaly: {
      main: "#FFFF00",
    },
    orange: {
      main: "#FF5F1F",
    },
    blue: {
      main: "#00bfff",
    },
    messageText: {
      main: "#3c4b64",
    },
    background: {
      paper: "#fff",
    },
    device: {
      main: "#9ABAED",
      contrastText: "#fff",
    },
    group_and_district: {
      main: "#506FA1",
      contrastText: "#fff",
    },
    network: {
      main: "#3c4b64",
      contrastText: "#fff",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: window?.THEME?.menu,
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: window.THEME.button,
          backgroundColor: window?.THEME?.primary,
          "&:hover": { backgroundColor: window?.THEME?.primary },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          fontSize: "12px",
        },
        cell: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 50,
        },
      },
    },
  },
});

export default theme;
