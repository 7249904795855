import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import NetworkEdgeWidget from "./NetworkEdgeWidget";
import { store } from "../../redux/store";
import Point from "../form/formComponents/Point";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import theme from "../../theme";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "edge" || element.name === "coordinates") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const NetworkEdgeCreate = () => {
  const { t } = useTranslation();
  const [createGateway, { isSuccess }] = store.useCreateGatewayMutation();
  const [formData, setFormData] = useState({});
  const [ready, setReady] = useState(false);
  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const fields = {
    edge: NetworkEdgeWidget,
    search: SearcheableSelect,
    point: Point,
  };

  const schema = {
    /*  title: "New Device", */
    type: "object",
    properties: {
      name: {
        $id: "5",
        type: "string",
        title: t("name"),
      },

      street_address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },
      serial_number: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      },
      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: "Europe/Rome",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },
      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      edge: {
        type: "array",
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    edge: {
      "ui:field": "edge",
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },
  };
  useEffect(() => {
    console.log("pise4kapopo4ka", formData);
  }, [formData]);
  const onSubmitClick = async () => {
    const edges = formData?.edge;

    for (let i = 0; i < edges?.length; i++) {
      const item = edges[i];
      const twinId = item?.twin?.id;
      const networkAdapters = window.GATEWAYS?.[twinId]?.map((item) => ({
        name: item,
        module: "null",
      }));
      const attributes = {
        fields: {
          serial_number: item?.serial,
          active_network_adapters: networkAdapters?.length > 0 && [
            ...networkAdapters,
          ],
          network_adapters: networkAdapters?.length > 0 && [...networkAdapters],
          class: "gateway",
          coordinates: {
            coordinates: [0, 0],
            type: "Point",
          },
        },
      };

      try {
        const createGatewayResult = await createGateway({
          id: twinId,
          attributes: attributes,
        }).unwrap();

        if (isSuccess(createGatewayResult)) {
          if (item?.area) {
            const loraAttributes = {
              values: {
                command: "create_gateway",
                content: {
                  mac: item.serial,
                  area: item.area,
                },
              },
            };

            await postCommands({
              attributes: loraAttributes,
              commandName: "configure",
              digitalTwinId: window.LORA,
              digitalTwinInstance: item?.server?.id,
            }).unwrap();
          }
        } else {
          console.error(
            "Error occurred while creating gateway:",
            createGatewayResult.error
          );
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  return (
    <>
      <Typography
        variant="h2"
        sx={{ color: theme?.palette?.primary?.main, mb: 2 }}
      >
        {t("create_edge")}
      </Typography>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick()}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        /*      customValidate={customValidate} */
        noHtml5Validate

        /*  templates={{ ErrorListTemplate }} */
      />
    </>
  );
};
export default NetworkEdgeCreate;
