import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { Box } from "@mui/material";
import AuthProvider from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import Campaigns from "./components/campaign/Campaigns";
import { useSelector } from "react-redux";
import UserShow from "./components/users/UserShow";
import DistrictIndex from "./components/DeviceGroups/DistrictsIndex";
import UserCreate from "./components/users/UserCreate";
import { Routes, Route } from "react-router-dom";
import UpdateMeter from "./components/metrics/UpdateMeter";
import LiveMessages from "./components/messages/LiveMessages";
import CreateNote from "./components/gateway/CreateNote";
import GroupDeviceCreate from "./components/DeviceGroups/GroupDeviceCreate";
import { Provider } from "react-redux";
import Uploader from "./components/FileUploader /Uploader";
/* import store from "./redux/store"; */
import ApartamentMterShow from "./components/condominium/ApartamentMeterShow";
import ErrorAssociationShow from "./components/meterErrors/ErrorAssociationShow";
import Logged from "./components/Logged";
import ShowMeter from "./components/metrics/ShowMeter";
import DigitalTwinShow from "./components/digitaltwin/DigitalTwinShow";
import GatewayCreate from "./components/gateway/GatewayCreate";
import GatewayShow from "./components/gateway/GatewayShow";
import AlarmsIndex from "./components/meterErrors/AlarmsIndex";
import MaintenancePage from "./utils/MaintenancePage";
import GatewayIndex from "./components/gateway/GatewayIndex";
import CreateWidget from "./components/widgets/CreateWidget";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ErrorAssociationIndex from "./components/meterErrors/ErrorAssociationIndex";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import LoginPage from "./components/Login";
import ApartmentCreate from "./pages/ApartamentCreate";
import DashboardCreate from "./components/dashboard/DashboardCreate";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import MeterCreate from "./components/form/MeterCreate";
import Meters from "./components/form/Meters";
import Devices from "./pages/Devices";
import CreateDevice from "./pages/devices/CreateDevice";
import GatewayUpdate from "./components/gateway/GatewayUpdate";
import ShowDevice from "./pages/ShowDevice";
import CreateErrorAssociation from "./components/meterErrors/CreateErrorAssociation";
import AuthenticatedProvider from "./components/AuthenticatedProvider";
import DeviceGroups from "./pages/DeviceGroups";
import DeviceDistricts from "./pages/DeviceDistricts";
import ErrorTypeIndex from "./components/meterErrors/ErrorTypeIndex";
import DeviceGroupCreate from "./pages/DeviceGroupCreate";
import DeviceGroup from "./pages/DeviceGroup";
import DeviceGroupEdit from "./pages/device_groups/DeviceGroupEdit";
import AddDeviceToGroup from "./pages/device_groups/AddDeviceToGroup";
import DeviceDistrictCreateUpdate from "./pages/DeviceDistrictCreateUpdate";
import Dashboard from "./pages/Dashboard";
import CreateMeter from "./components/metrics/CreateMeter";
import ShowNote from "./components/gateway/ShowNote";
import PositionCreate from "./components/position/PositionCreate";
import CampaignCreate from "./components/campaign/CampaignCreate";
import Users from "./components/users/Users";
import CampaignShow from "./components/campaign/CampaignShow";
import theme from "./theme";
import GlobalLoading from "./utils/GlobalLoading";
import themeTrilliant from "./themeTrilliant";
import { ThemeProvider } from "@mui/material";
import GroupDeviceShow from "./components/DeviceGroups/GroupsDeviceShow";
import Dex from "./pages/Dex";
import CustomSnackbar from "./utils/Snackbars";
import NetworkAdapters from "./pages/NetworkAdapters";
import CustomerEndpoints from "./pages/CustomerEndpoints";
import CustomerEndpointCreate from "./pages/CustomerEndpointCreate";
import { createRoot } from "react-dom/client";
import Messages from "./components/messages/Messages";
import CustomerEndpointUpdate from "./pages/CustomerEndpointUpdate";
import MeterShow from "./components/metrics/MeterShow";
import Riccardo from "./pages/Riccardo";
import FallbackLoader from "./utils/FallbackLoader";
import ErrorsHandler from "./utils/ErrorsHandler";
import TestLik from "./components/TestLink";
import StatisticShow from "./components/statistics/StatisticShow";
import Metrics from "./pages/Metrics";
import MyAccount from "./pages/MyAccount";
import StatisticCreate from "./components/statistics/StatisticCreate";
import TestBody from "./utils/testBody";
import WidgetShow from "./components/widgets/WidgetShow";
import Statistics from "./components/statistics/Statistics";
import NotFoundPage from "./utils/NotfoundPage";
import ErrorsShow from "./utils/ErrorsShow";
import Widgets from "./components/widgets/Widgets";
import Condominium from "./pages/Condominium";
import HelmetHeader from "./utils/HelmetHeader";
import ErrorTypeShow from "./components/meterErrors/ErrorTypeShow";
import CreateErrorType from "./components/meterErrors/CreateErrorType";
import DigitalTwinIndex from "./components/digitaltwin/DigitalTwinIndex";
import { CondominiumShow } from "./pages/CondominiumShow";
import { SubCondominiumShow } from "./pages/SubCondominiumShow";
import { ApartmentShow } from "./pages/ApartmentShow";
import { setupStore } from "./redux/store";
import { ErrorBoundary } from "react-error-boundary";
import { IwdRoutes } from "./utils/Routes";
import { IwdRoute } from "./utils/IwdRoute";
const store = setupStore({});
function Ade() {
  // 👇️ redirect to external URL
  window.location.replace("http://192.168.1.65:9295/");

  return null;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
library.add(fab, faCheckSquare, faCoffee);

const container = document.getElementById("root");
const root = createRoot(container);
function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
root.render(
  <Provider store={store}>
    <HelmetHeader />

    <React.StrictMode>
      {/*     <ErrorBoundary FallbackComponent={CustomFallback}> */}
      <Suspense /* fallback={<FallbackLoader />} */>
        <GlobalLoading />
        {/*    <ErrorsHandler /> */}
        <CustomSnackbar />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AuthenticatedProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    {!window.MAINTENANCE
                      ? IwdRoutes?.map((item) => (
                          <Route
                            path={item?.path}
                            element={
                              <IwdRoute
                                auth={item?.auth}
                                component={item?.component}
                                section={item?.section}
                              />
                            }
                          />
                        ))
                      : IwdRoutes?.map((item) => (
                          <Route
                            path={item?.path}
                            element={
                              <IwdRoute
                                auth={item?.auth}
                                component={<MaintenancePage />}
                                section={item?.section}
                              />
                            }
                          />
                        ))}
                  </Route>
                </Routes>
              </BrowserRouter>
            </AuthenticatedProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
      {/*   </ErrorBoundary> */}
    </React.StrictMode>
  </Provider>
);
reportWebVitals();
