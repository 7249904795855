import { createSlice } from "@reduxjs/toolkit";
import { gatewayApi } from "./gatewayApi";
import { store } from "../store";
const initialState = {
  gataways: null,
  gateway: null,
  metaCount: null,
  messages: null,
  edgeMessages: null,
  edgeMEssagesCount: null,
  gatewayId: null,
  twinId: null,
  messagesMeta: null,
  readedMessages: null,
  readedMessagesCount: null,
  unreadedMessages: null,
  unreadedMessagesCount: null,
  handledMeterMessages: null,
  errorMetersMessages: null,
  errorMessagesCount: null,
  fieldsToUpdate: null,
  gatewayTabIndex: 0,
  trashId: null,
  readedMessagesFirstRender: true,
  unreadedMessagesFirstRender: true,
  errorMessagesFirstRender: true,
  messagesFirstRender: true,
  loraAreas: null,
  loraMeterId: null,
  loraProfiles: null,
  edgeShow: null,
  whiteList: null,
  unseenGwmessages: null,
  unseenGwCount: null,
  unseenGwFirstRender: true,
};

const gatewaySlice = createSlice({
  name: "gatewaySlice",
  initialState,
  reducers: {
    cleanGatewayData(state) {
      state.twinId = null;
      state.readedMessagesFirstRender = true;
      state.messagesFirstRender = true;
      state.unreadedMessagesFirstRender = true;
      state.errorMessagesFirstRender = true;
      state.errorMetersMessages = null;
      state.readedMessages = null;
      state.unreadedMessages = null;
      state.messages = null;
      state.gatewayId = null;
      state.unseenGwCount = null;
      state.unseenGwFirstRender = true;
      state.unseenGwmessages = null;
    },
    setGateway(state, action) {
      state.gateway = action.payload;
      /*  state.edgeShow = action.payload; */
    },
    setFieldsToUpdate(state, action) {
      state.fieldsToUpdate = action.payload;
    },
    setTabIndex(state, action) {
      state.gatewayTabIndex = action.payload;
    },
    resetIndex(state) {
      state.gatewayTabIndex = 0;
    },
    cleanGatewayMessages(state) {
      state.readedMessages = null;
      state.unreadedMessages = null;
    },
    setGatewayTrashId(state, action) {
      state.trashId = action.payload;
    },
    setLoraAreas(state, action) {
      state.loraAreas = action.payload?.areas;
      state.loraMeterId = action.payload?.id;
      state.loraProfiles = action.payload?.profiles;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.gatewayEndpoints.getIndexGateway.matchFulfilled,
      (state, action) => {
        state.gataways = action.payload.data;
        state.metaCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getShowGateway.matchFulfilled,

      (state, action) => {
        if (action.payload.data.attributes.fields.class === "edge_controller") {
          state.edgeShow = action.payload.data.attributes;
          state.twinId = action.payload.data.attributes.digital_twin_id;
          state.gatewayId = action.payload.data.id;
        } else {
          state.gateway = action.payload.data.attributes;
          state.twinId = action.payload.data.attributes.digital_twin_id;
          state.gatewayId = action.payload.data.id;
          state.whiteList =
            action.payload?.data?.attributes?.fields?.white_list;
        }
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getGatewayMessages.matchFulfilled,
      (state, action) => {
        state.messages = action.payload.data;
        state.messagesMeta = action.payload.meta.count;
        state.messagesFirstRender = false;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getEdgeMessages.matchFulfilled,
      (state, action) => {
        state.edgeMessages = action.payload.data;
        state.edgeMEssagesCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getMeterReadedMessages.matchFulfilled,
      (state, action) => {
        state.readedMessages = action.payload.data;
        state.readedMessagesCount = action.payload.meta.count;
        state.readedMessagesFirstRender = false;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getHandledMeterMessages.matchFulfilled,

      (state, action) => {
        state.handledMeterMessages = action.payload.data;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getMeterUnreadedMessages.matchFulfilled,

      (state, action) => {
        state.unreadedMessages = action.payload.data;
        state.unreadedMessagesCount = action.payload.meta.count;
        state.unreadedMessagesFirstRender = false;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getErrorMeterMessages.matchFulfilled,

      (state, action) => {
        state.errorMetersMessages = action.payload.data;
        state.errorMessagesCount = action.payload.meta.count;
        state.errorMessagesFirstRender = false;
      }
    );
    builder.addMatcher(
      store.gatewayEndpoints.getUnseenGwMessages.matchFulfilled,

      (state, action) => {
        state.unseenGwmessages = action.payload.data;
        state.unseenGwCount = action.payload.meta.count;
        state.unseenGwFirstRender = false;
      }
    );
  },
});
export const {
  cleanGatewayData,
  setGateway,
  setFieldsToUpdate,
  setTabIndex,
  resetIndex,
  cleanGatewayMessages,
  setGatewayTrashId,
  setLoraAreas,
} = gatewaySlice.actions;
export default gatewaySlice.reducer;
