import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../redux/store";
import Box from "@mui/material/Box";

import { setDigitalTwin } from "../../../redux/slices/digitalTwinSlice";

import { useEffect, useState } from "react";
import { nameTransform } from "../../../utils/utilsFunctions";
import Checkbox from "@mui/material/Checkbox";
import { setTransofrmName } from "../../../redux/slices/meterSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import { setSelectedMeter } from "../../../redux/slices/meterSlice";
import { setRestTwin } from "../../../redux/slices/actionsSlice";
import { Diamond } from "@mui/icons-material";
const timeZoneDefaultValue = [
  { title: "The Shawshank Redemption", year: 1994 },
];

const DtwinCustomField = (props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const resetTwin = useSelector((state) => state?.actionsSlice?.resetTwin);
  const [selectedMeterId, setSelectedmeterId] = useState(null);
  const [index, setIndex] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [options, setOptions] = useState([]);
  const [transformedOptions, setTransofrmedOptions] = useState([]);
  const [getAttributes] = store.useLazyGetMeterAttributesQuery();
  const transformName = useSelector(
    (state) => state?.meterSlice?.transformName
  );
  const [value, setValue] = useState(props.formData ? props.formData : "");
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataQuery();
  const fromIndex = props?.schema?.fromIndex;
  const trnasormFn = (arr) => {
    return arr
      ?.map((item) => ({
        key: item.attributes?.id,
        label: `${nameTransform(item.attributes?.name)} (${
          item.attributes?.version
        })`,
        value: `${nameTransform(item.attributes?.name)} (${
          item.attributes?.version
        })`,
        tags: item?.attributes?.digital_twin_fields?.tags,
        id: item?.attributes?.id,
      }))
      ?.filter((item) => item?.tags?.includes("Meter"));
  };
  const diameter = props?.schema?.dispatched
    ? result?.data?.data?.filter((item) => item?.attributes?.id == value?.key)
    : null;
  let data = result && props?.schema?.mapFn(result);

  /*   let tranformNameData = result ? props?.schema?.trasformFn(result) : []; */
  const transformedData = result && trnasormFn(result?.data?.data);
  useEffect(() => {
    setOptions(data);
    setTransofrmedOptions(transformedData);
  }, [result]);
  useEffect(() => {
    console.log("options", options);
    console.log("optionstr", transformedOptions);
  }, [options, transformedOptions]);
  const selectedMeter = props?.schema?.dispatched
    ? result?.data?.data?.filter((item) => item?.attributes?.id == value?.key)
    : null;
  useEffect(() => {
    if (value && value != "" && value != props.formData) {
      props.onChange(fromIndex ? value.id : value.value);
    }
  }, [value]);
  useEffect(() => {
    if (value == null || value == undefined) {
      props.onChange(null);
    }
  }, [value]);
  useEffect(() => {
    console.log("суктщргш", props);
  }, [props]);

  /*   useEffect(() => {
    nameTransform("Maddalena Gradus - EFE:13:08 (1.0.1)");
  }, []); */
  /*  useEffect(() => {
    if (transformName && result) {
   
      let filteredMeters = result?.data?.data?.filter
(
(item) =>
        item?.attributes?.digital_twin_fields?.tags?.includes("WmBus")
      );
      let otherMeters = result?.data?.data?.filter
(

        (item) =>
          !item?.attributes?.digital_twin_fields?.tags?.includes("WmBus")
      );
      console.log("meterfilter", filteredMeters);
      console.log("meterother", otherMeters);
    }
  }, [transformName, result]); */
  /*   useEffect(() => {
    if (transformName && result) {
      data.map;
    }
  }, [transformName, result]); */
  useEffect(() => {
    console.log("pezdahuihui", props.schema.url);
  }, [props]);
  useEffect(() => {
    if (props?.schema?.dispatched) {
      dispatch(setDigitalTwin(diameter));
    }
  }, [value]);

  useEffect(() => {
    if (props?.schema?.dispatched) {
      dispatch(setSelectedMeter(selectedMeter));
    }
  }, [selectedMeter]);
  useEffect(() => {
    if (selectedMeter?.length > 0) {
      setSelectedmeterId(selectedMeter[0].id);
    }
  }, [selectedMeter]);
  useEffect(() => {
    if (selectedMeterId) {
      getAttributes(selectedMeterId);
    }
  }, [selectedMeterId]);

  useEffect(() => {
    if (resetTwin) {
      setValue("");
      dispatch(setRestTwin(false));
    }
  }, [resetTwin]);

  useEffect(() => {
    if (open) {
      Promise.all([trigger(props.schema.url)]).then(() => {
        setLoading(false);
        setFirstRender(false);
      });
    }
  }, [open, transformName]);

  const openFunc = () => {
    setOpen(true);
    if (firstRender) {
      setLoading(true);
    }
  };
  useEffect(() => {
    if (!firstRender && transformName && index) {
      setValue(transformedOptions[index]);
    }
    if (!firstRender && !transformName && index) {
      setValue(options[index]);
    }
  }, [index, transformName]);

  useEffect(() => {
    console.log("valuedgtltwn", value);
  }, [value]);

  return (
    <>
      <Autocomplete
        fullWidth
        value={value}
        open={open}
        onOpen={openFunc}
        onClose={() => setOpen(false)}
        options={transformName ? transformedOptions ?? [] : options ?? []}
        defaultValue={props?.schema?.defaultTimeZone && "Europe/Rome"}
        popupIcon={""}
        onChange={(e, newValue) => {
          const selectedIndex = transformName
            ? transformedOptions.indexOf(newValue)
            : options.indexOf(newValue);
          /*  const transformIndex = transformedOptions.indexOf(newValue); */
          setIndex(selectedIndex);
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <Box display={"flex"} alignItems={"center"}>
              <TextField
                required={props.required}
                {...params}
                error={props?.rawErrors?.length > 0}
                label={props.schema.title}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {props?.schema?.checkbox && (
                <Tooltip title={!transformName ? "Hex to dec" : "Dec to hex"}>
                  <FormControlLabel
                    required
                    labelPlacement="bottom"
                    control={
                      <Checkbox
                        defaultChecked
                        onClick={() => dispatch(setTransofrmName())}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          );
        }}
      />
    </>
  );
};

export default DtwinCustomField;
