export const errorList = {
  "Axioma Qualsonic W1 AXI - AXI:16:07": [
    "burst",
    "reverse_flux",
    "underflow_temperature",
    "leakage",
    "temperory_error",
    "permanent_error",
    "low_battery",
    "abnormal_condition",
  ],

  "BMeters hydrocal - BMT:11:13": ["manual_unlear"],

  "BMeters Tx1.1 - BMT:19:07": [
    "overflow_flux",
    "sw_error",
    "leakage",
    "low_battery",
    "hw_tamper",
  ],
  "Englemann EFE - EFE:85:08": [
    "hw_error",
    "sabotage",
    "low_battery",
    "hf_error",
    "reset",
  ],

  "R.B.M HCA - RBM:133:08": [
    "hw_error",
    "sabotage",
    "low_battery",
    "hf_error",
    "reset",
  ],

  "Engelmann SensoStar S2 - EFE:00:19": [
    "checksum_error",
    "memory_error",
    "reset",
    "scanning_coil_fault",
    "reference_sensor_fault",
    "return_flow_sensor_fault",
    "forward_flow_sensor_fault",
  ],

  "Englemann SensoStar S3 - EFE:00:13": [
    "low_battery",
    "reset",
    "damaged_electronics",
    "flow_sensor_error",
    "temp_sensor2_short_circuit",
    "temp_sensor2_broken_cable",
    "temp_sensor1_short_circuit",
    "temp_sensor1_broken_cable",
  ],

  "Generic Water Meter Pulse Counter": [
    "low_battery",
    "reset",
    "damaged_electronics",
    "flow_sensor_error",
    "temp_sensor2_short_circuit",
    "temp_sensor2_broken_cable",
    "temp_sensor1_short_circuit",
    "temp_sensor1_broken_cable",
  ],
  "Maddalena MicroClimaEvo - MAD:00:13": [
    "low_battery",
    "reset",
    "damaged_electronics",
    "flow_sensor_error",
    "temp_sensor2_short_circuit",
    "temp_sensor2_broken_cable",
    "temp_sensor1_short_circuit",
    "temp_sensor1_broken_cable",
  ],

  "Maddalena Arrow MAD - MAD:129:07": [
    "leakage",
    "reverse_flux",
    "hw_error",
    "magnetic_tamper",
    "hw_tamper",
  ],

  "Maddalena Arrow MVM - MAD:135:07": [
    "low_battery",
    "reverse_flux",
    "broken_cable",
    "generic_alarm",
    "leakage",
    "magnetic_tamper",
    "overflow_flux",
  ],

  "Maddalena ElectoSJ - MAD:01:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],

  "Maddalena Gradus - EFE:17:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],
  "Maddalena Gradus - EFE:19:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],
  "Maddalena Gradus - EFE:37:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],
  "Maddalena Gradus - EFE:41:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],
  "Maddalena Gradus - EFE:49:08": [
    "broken_sensor",
    "memory_error",
    "sw_reset",
    "hw_reset",
    "hw_tamper",
    "not_installed",
    "lifetime_more_than_ten_years",
  ],

  "Maddalena Idrico FAW - EFE:01:07": [
    "hw_error",
    "magnetic_tamper",
    "block_detected",
    "reset",
    "sw_error",
    "module_removed",
    "leakage",
    "reverse_flux",
  ],
  "Maddalena Idrico FAW - EFE:03:07": [
    "hw_error",
    "magnetic_tamper",
    "block_detected",
    "reset",
    "sw_error",
    "module_removed",
    "leakage",
    "reverse_flux",
  ],

  "Maddalena Radio Evo - MAD:80:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "Maddalena Radio Evo - MAD:80:22": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "Maddalena Radio Evo - MAD:81:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],
  "Maddalena Radio Evo - MAD:130:07": [
    "empty_pipe",
    "reverse_flux",
    "overflow_flux",
    "overflow_reverse_flux",
    "leakage",
    "magnetic_fraud",
    "hw_tamper",
  ],

  "Sontex 739 - SON:27:13": ["generic_alarm", "temp_sensor1_short_circuit"],

  "Sontex 868 - SON:22:08": [
    "low_battery",
    "hw_tamper",
    "measure_error",
    "key_too_long_closed",
    "overflow_temperature",
    "unknown_field_C",
    "unknown_field_CI",
    "sw_tamper",
    "rssi_not_available",
    "snd_udframe_structured_write_unkwnow_field",
    "snd_udframe_unkwnow_ci",
  ],
};

export const integraErrors = {
  "Valve Controlled Ultrasonic Water Meter (1.2.0)": [
    "tamper",
    "clock_calibration",
    "clock_calibration",
    "temp_sensor_short_circuit",
    "temp_sensor_short_circuit",
    "pressure_measurement_fault",
    "no_water",
    "crystal_oscillator_failure",
    "spi_interface_fault",
    "external_power_supply_failure",
    "serial_port_failure",
    "gps_positioning_failure",
    "reverse_flow",
    "pulse_overload",
    "low_battery_warning",
    "low_battery_alarm",
    "eeprom_failure",
    "leakage_alarm",
    "leakage_alarm1",
    "cover_removal_alarm",
    "cover_removal_alarm",
    "flow_upper_limit_alarm",
    "flow_lower_limit_alarm",
    "flow_lower_limit_alarm",
    "total_flow_alarm",
    "daily_usage_alarm",
    "water_temp_upper_limit_alarm",
    "water_temp_lower_limit_alarm",
    "pressure_upper_limit_alarm",
    "pressure_lower_limit_alarm",
    "low_voltage_write_flash_alarm",
    "current_low_speed_clock_source",
    "caf_abnormal_close_valve",
    "startup_segment_broken_circuit",
    "time_of_startup_segment_lower_than_limit",
    "time_of_startup_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "total_operation_time_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "motor_jammed",
    "short_circuit",
    "leakage_alarm2",
    "leakage_alarm3",
    "leakage_alarm4",
    "leakage_alarm5",
    "leakage_alarm6",
    "leakage_alarm7",
    "leakage_alarm8",
    "reverse_low_flow_alarm",
    "reverse_large_flow_alarm",
    "combined_leakage_alarm",
    "reverse_install_alarm",
    "flow_storage_abnormal_alarm",
    "iic_bus_abnormal_alarm",
    "locked_rotor",
    "jammed_motor",
    "time_out",
    "high_power_consumption",
    "valve_status",
    "valve_abnormal",
    "reach_the_designated_position_again",
    "broken_wire",
    "open_valve_status",
    "close_valve_status",
    "open_repeatedly",
    "close_repeatedly",
    "broken_circuit_of_startup_segment",
    "time_of_start_up_segment_lower_than_lower_limit",
    "time_of_start_up_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_lower_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "jammed_motor",
    "short_circuit",
    "valve_not_in_place_probably",
    "self_check_has_been_executed",
  ],
  "Valve Controlled Ultrasonic Water Meter (1.0.11)": [
    "tamper",
    "clock_calibration",
    "clock_calibration",
    "temp_sensor_short_circuit",
    "temp_sensor_short_circuit",
    "pressure_measurement_fault",
    "no_water",
    "crystal_oscillator_failure",
    "spi_interface_fault",
    "external_power_supply_failure",
    "serial_port_failure",
    "gps_positioning_failure",
    "reverse_flow",
    "pulse_overload",
    "low_battery_warning",
    "low_battery_alarm",
    "eeprom_failure",
    "leakage_alarm",
    "leakage_alarm1",
    "cover_removal_alarm",
    "cover_removal_alarm",
    "flow_upper_limit_alarm",
    "flow_lower_limit_alarm",
    "flow_lower_limit_alarm",
    "total_flow_alarm",
    "daily_usage_alarm",
    "water_temp_upper_limit_alarm",
    "water_temp_lower_limit_alarm",
    "pressure_upper_limit_alarm",
    "pressure_lower_limit_alarm",
    "low_voltage_write_flash_alarm",
    "current_low_speed_clock_source",
    "caf_abnormal_close_valve",
    "startup_segment_broken_circuit",
    "time_of_startup_segment_lower_than_limit",
    "time_of_startup_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "total_operation_time_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "motor_jammed",
    "short_circuit",
    "leakage_alarm2",
    "leakage_alarm3",
    "leakage_alarm4",
    "leakage_alarm5",
    "leakage_alarm6",
    "leakage_alarm7",
    "leakage_alarm8",
    "reverse_low_flow_alarm",
    "reverse_large_flow_alarm",
    "combined_leakage_alarm",
    "reverse_install_alarm",
    "flow_storage_abnormal_alarm",
    "iic_bus_abnormal_alarm",
    "locked_rotor",
    "jammed_motor",
    "time_out",
    "high_power_consumption",
    "valve_status",
    "valve_abnormal",
    "reach_the_designated_position_again",
    "broken_wire",
    "open_valve_status",
    "close_valve_status",
    "open_repeatedly",
    "close_repeatedly",
    "broken_circuit_of_startup_segment",
    "time_of_start_up_segment_lower_than_lower_limit",
    "time_of_start_up_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_lower_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "jammed_motor",
    "short_circuit",
    "valve_not_in_place_probably",
    "self_check_has_been_executed",
  ],

  "Smart Control Valve (1.0.0)": [
    "tamper",
    "clock_calibration",
    "clock_calibration",
    "temp_sensor_short_circuit",
    "temp_sensor_short_circuit",
    "pressure_measurement_fault",
    "no_water",
    "crystal_oscillator_failure",
    "spi_interface_fault",
    "external_power_supply_failure",
    "serial_port_failure",
    "gps_positioning_failure",
    "reverse_flow",
    "pulse_overload",
    "low_battery_warning",
    "low_battery_alarm",
    "eeprom_failure",
    "leakage_alarm",
    "leakage_alarm1",
    "cover_removal_alarm",
    "cover_removal_alarm",
    "flow_upper_limit_alarm",
    "flow_lower_limit_alarm",
    "flow_lower_limit_alarm",
    "total_flow_alarm",
    "daily_usage_alarm",
    "water_temp_upper_limit_alarm",
    "water_temp_lower_limit_alarm",
    "pressure_upper_limit_alarm",
    "pressure_lower_limit_alarm",
    "low_voltage_write_flash_alarm",
    "current_low_speed_clock_source",
    "caf_abnormal_close_valve",
    "startup_segment_broken_circuit",
    "time_of_startup_segment_lower_than_limit",
    "time_of_startup_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "total_operation_time_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "motor_jammed",
    "short_circuit",
    "leakage_alarm2",
    "leakage_alarm3",
    "leakage_alarm4",
    "leakage_alarm5",
    "leakage_alarm6",
    "leakage_alarm7",
    "leakage_alarm8",
    "reverse_low_flow_alarm",
    "reverse_large_flow_alarm",
    "combined_leakage_alarm",
    "reverse_install_alarm",
    "flow_storage_abnormal_alarm",
    "iic_bus_abnormal_alarm",
    "locked_rotor",
    "jammed_motor",
    "time_out",
    "high_power_consumption",
    "valve_status",
    "valve_abnormal",
    "reach_the_designated_position_again",
    "broken_wire",
    "open_valve_status",
    "close_valve_status",
    "open_repeatedly",
    "close_repeatedly",
    "broken_circuit_of_startup_segment",
    "time_of_start_up_segment_lower_than_lower_limit",
    "time_of_start_up_segment_exceed_upper_limit",
    "time_of_operation_segment_exceed_upper_limit",
    "time_of_operation_segment_lower_than_lower_limit",
    "electrical_current_of_operation_segment_exceed_upper_limit",
    "time_of_end_segment_exceed_upper_limit",
    "time_of_end_segment_lower_than_lower_limit",
    "operation_power_consumption_exceeds_preset_value",
    "valve_isnt_in_place",
    "valve_is_blocked",
    "need_abnormal_handling",
    "valve_self_check_abnormal",
    "jammed_motor",
    "short_circuit",
    "valve_not_in_place_probably",
    "self_check_has_been_executed",
  ],
};
