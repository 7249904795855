import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@material-ui/core";
import GraficCard from "./formComponents/GraphicCard";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useEffect } from "react";
import { Typography } from "@mui/material";

const ReadignConsumptionsGraph = ({
  consumptions,
  readingGraphData,
  readingMessagesData,
  mixedData,
}) => {
  const consumptionsGraphData = consumptions?.map((item) => ({
    name: new Date(item?.date).toLocaleDateString(),
    value: item?.value /* .toFixed(3) */,
    type: item.type,
  }));

  useEffect(() => {
    console.log("mixedData", mixedData);
  }, [consumptions, readingGraphData, readingMessagesData]);
  return (
    <>
      {mixedData.length > 0 && (
        <ComposedChart
          width={1500}
          height={400}
          data={mixedData}
          /* margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }} */
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="consumptionsValue" fill="#8884d8" />
          <Line dataKey="readingValue" stroke="#ff7300" />
        </ComposedChart>
      )}
    </>
  );
};
export default ReadignConsumptionsGraph;
