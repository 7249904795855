import WorklistEditorMap from "./WorklistEditorMap";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import WorklistEditorShowForm from "./WorklistEditorShowForm";
import WorklistEditorShowTable from "./WorklistEditorShowTable";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
const WorklistEditorShow = () => {
  const [showMode, setShowMode] = useState(null);
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  useEffect(() => {
    console.log("shwmd", showMode);
  }, [showMode]);
  return (
    <>
      <Grid
        container
        sx={{
          height: "75vh",
          width: appBarIsOpen ? "78vw" : "92vw",
        }}
      >
        <Grid item xs={3} sx={{ mt: 5 }}>
          <WorklistEditorShowForm
            setShowMode={setShowMode}
            showMode={showMode}
          />
        </Grid>
        {showMode && (
          <Grid item xs={9}>
            <Box sx={{ height: "93%", width: "100%", ml: 11 }}>
              {showMode === "map" ? (
                <WorklistEditorMap />
              ) : (
                <WorklistEditorShowTable />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default WorklistEditorShow;
