import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";
import DistrictTable from "./DistrictTable";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import { Box, Grid, Button } from "@mui/material";
import DistrictSearchBar from "./DistrictSearchBar";
const DistrictIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [deleteDistrict, { isSuccess: deleteSuccess }] =
    store.useDeleteDistrictMutation();
  const [getDistricts] = store.useLazyGetDistrictsQuery();
  const deleteDistrictId = useSelector(
    (state) => state?.deviceDistrictSlice.deleteDistrictId
  );
  const params = {
    page: page,
    perPage: perPage,
  };
  useEffect(() => {
    getDistricts(params);
  }, [page]);
  useEffect(() => {
    if (deleteDistrictId) {
      deleteDistrict(deleteDistrictId);
      dispatch(actions.setDeleteDistrictId(null));
    }
  }, [deleteDistrictId]);
  useEffect(() => {
    if (deleteSuccess) {
      getDistricts(params);
    }
  }, [deleteSuccess]);
  return (
    <>
      <DistrictSearchBar params={params} />
      <DistrictTable perPage={perPage} page={page} setPage={setPage} />
      <Box
        sx={{ mt: 2 }}
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
      >
        <Button variant="contained" onClick={() => navigate("/groups/create")}>
          {t("create_group")}
        </Button>
      </Box>
    </>
  );
};
export default React.memo(DistrictIndex);
