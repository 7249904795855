import { Grid, Typography, Button, Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setUpdateMode } from "../../redux/slices/meterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setMeterIdToUpdate,
  setMeterShow,
} from "../../redux/slices/meterSlice";

const HeatCostTab = ({ result, enteredFromCondominium, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const MeterIdToUpdate = useSelector(
    (state) => state?.meterSlice?.MeterIdToUpdate
  );
  const onUpdateClick = () => {
    if (!enteredFromCondominium) {
      dispatch(setMeterIdToUpdate(id));
      dispatch(setUpdateMode("hca"));

      navigate("edit");
    }
    if (enteredFromCondominium) {
      localStorage.setItem("meterShow", JSON.stringify(result?.data?.data));
      window.open(`/meters/${MeterIdToUpdate}/edit`, "_blank");
    }
  };
  useEffect(() => {
    console.log("pisipopix", result);
  }, [result]);
  return (
    <>
      <Grid container spacing={4}>
        {result?.attributes?.fields?.radiator_height && (
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("radiator_height")} :
            </Typography>
            <Typography variant="subtitle1">
              {result?.attributes?.fields?.radiator_height + " mm" ?? "--"}
            </Typography>
          </Grid>
        )}
        {result?.attributes?.fields?.radiator_width && (
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("radiator_width")} :</Typography>
            <Typography variant="subtitle1">
              {result?.attributes?.fields?.radiator_width + " mm" ?? "--"}
            </Typography>
          </Grid>
        )}
        {result?.attributes?.fields?.radiator_depth && (
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("radiator_depth")} :</Typography>
            <Typography variant="subtitle1">
              {result?.attributes?.fields?.radiator_depth + " mm" ?? "--"}
            </Typography>
          </Grid>
        )}

        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("radiator_c")} :</Typography>
          <Typography variant="subtitle1">
            {result?.attributes?.fields?.radiator_c
              ? `${result?.attributes?.fields?.radiator_c} W/m^3 `
              : "--"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {t("factory_converter")} :
          </Typography>
          <Typography variant="subtitle1">
            {t("number", {
              val: result?.attributes?.fields?.factory_converter,

              maximumFractionDigits: 3,
            })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("kq")} :</Typography>
          <Typography variant="subtitle1">
            {t("number", {
              val: result?.attributes?.fields?.kq,

              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("k")} :</Typography>
          <Typography variant="subtitle1">
            {t("number", {
              val: result?.attributes?.fields?.k,

              maximumFractionDigits: 3,
            })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{t("kc")} :</Typography>
          <Typography variant="subtitle1">
            {t("number", {
              val: result?.attributes?.fields?.kc,

              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
      </Grid>
      {!enteredFromCondominium && (
        <Box
          sx={{ mt: 5 }}
          display={"flex"}
          width={"100%"}
          justifyContent={"flex-end"}
        >
          <Button onClick={onUpdateClick} variant="contained">
            {t("update_meter")}
          </Button>
        </Box>
      )}
    </>
  );
};
export default HeatCostTab;
