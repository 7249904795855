import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import IconButton from "@mui/material/IconButton";
import { Tabs, Tab, Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
import {
  cleanUserGroup,
  setUsergroupTrashId,
} from "../../redux/slices/userGroupSlice";
import theme from "../../theme";
const UserGroupsIndex = () => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const userGroupTrashId = useSelector(
    (state) => state?.userGroupSlice?.userGroupTrashId
  );
  const userGroups = useSelector((state) => state?.userGroupSlice?.userGroups);
  const count = useSelector((state) => state?.userGroupSlice?.count);
  const [getUserGroups] = store.useLazyGetUserGroupsQuery();
  const [deleteUserGroup, { isSuccess: deleteSuccess }] =
    store.useDeleteUserGroupMutation();

  const onTrashClick = (id) => {
    setTrashId(id);
    setDeleteModal(true);
  };
  useEffect(() => {
    if (userGroupTrashId) {
      deleteUserGroup(userGroupTrashId);
    }
  }, [userGroupTrashId]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "description",
      headerName: t("description"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton
              onClick={() => navigate(`/usergroups/${params?.value}`)}
            >
              <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={() => onTrashClick(params?.value)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const rows = userGroups?.map((item) => ({
    id: item?.attributes?.id,
    name: item?.attributes?.name,
    description: item?.attributes?.description,
    actions: item?.attributes?.id,
  }));
  useEffect(() => {
    getUserGroups();
    dispatch(cleanUserGroup());
  }, []);

  useEffect(() => {
    return () => dispatch(setUsergroupTrashId(null));
  });

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setUsergroupTrashId(null));
      getUserGroups();
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteSuccess]);

  return (
    <>
      {userGroups && (
        <>
          <DeleteConfirmModal
            trashModal={deleteModal}
            setTrashModal={setDeleteModal}
            text={t("ug_delete_text")}
            title={t("ug_delete_title")}
            deleteFunc={() => setUsergroupTrashId(trashId)}
          />
          <Typography
            sx={{ mt: 2, mb: 4 }}
            color={theme.palette.primary.main}
            variant="h2"
          >
            {t("user_groups")}
          </Typography>
          <Box height={600}>
            <StyledDataGrid
              disableColumnMenu
              rows={rows ?? []}
              columns={columns}
              rowCount={count}
              pageSize={perPage}
              rowsPerPageOptions={[perPage]}
              disableSelectionOnClick
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              paginationMode="server"
              getRowHeight={() => "auto"}
            />
          </Box>
          <Button
            onClick={() => navigate("/usergroups/create")}
            sx={{ mt: 2, float: "right" }}
            variant="contained"
          >
            {t("create_usergroup")}
          </Button>
        </>
      )}
    </>
  );
};
export default UserGroupsIndex;
