import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
} from "recharts";

import theme from "../../theme";

const MeterReadingsGraph = ({ meter, unit }) => {
  const { t } = useTranslation();
  const readingsRangeElements = useSelector(
    (state) => state?.meterSlice?.readingsRangeElements
  );
  const data = useSelector((state) => state.meterReadingSlice?.data);

  const mappedData = data?.map((item) => {
    let numericValue = item.reading;

    if (typeof item.reading === "string") {
      numericValue = parseFloat(item.reading.replace(",", "."));
    }

    if (isNaN(numericValue)) {
      return {
        oneDay: item?.oneDay,
        reading: "Nessuna lettura",
      };
    } else {
      if (unit === "m3") {
        return {
          oneDay: item?.oneDay,
          reading: numericValue,
        };
      } else {
        return {
          oneDay: item?.oneDay,
          reading: numericValue /* * 1000 */,
        };
      }
    }
  });

  const [range, setRange] = useState({
    start: mappedData?.length > 10 ? mappedData?.length - 10 : 0,
    end: mappedData?.length - 1,
  });

  useEffect(() => {
    console.log("vpolnomahui", data?.length - 1);
  }, [data]);

  useEffect(() => {
    console.log("datagraph", mappedData);
  }, [mappedData]);
  const [ref, { width }] = useMeasure();

  if (!data) {
    return <Box ref={ref}>Loading</Box>;
  }

  return (
    <Box ref={ref}>
      <LineChart
        style={{ margin: 4 }}
        width={width - 10}
        height={550}
        data={mappedData}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <Tooltip
          labelFormatter={(value) => {
            return t("dt_n", { val: new Date(value) });
          }}
          formatter={(value) =>
            unit == "m3"
              ? t("number", { val: value })
              : typeof value == "number" && +value?.toFixed(0)
          }
        />
        <Line
          type="monotone"
          dataKey="reading"
          stroke={theme.palette.network.main}
          isAnimationActive={false}
        />
        <CartesianGrid stroke={theme.palette.device.main} />
        <XAxis
          dataKey="oneDay"
          label={{ value: t("date"), position: "insideBottom", offset: 0 }}
          angle={45}
          height={90}
          tickMargin={30}
          interval="preserveStartEnd"
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          stroke={theme.palette.group_and_district.main}
          tick={{ fontSize: 10 }}
        />
        <YAxis
          label={{
            value: t("m3"),
            angle: -45,
            position: "insideLeft",
          }}
          stroke={theme.palette.group_and_district.main}
          tickFormatter={(value) =>
            unit == "m3"
              ? t("number", { val: value })
              : typeof value == "number" && +value?.toFixed(0)
          }
          style={{ fontSize: "10px" }}
          width={90}
        />
        {/*  <Brush
          height={30}
          dataKey="brushDate"
          travellerWidth={10}
          startIndex={range?.start}
          endIndex={range?.end}
        /> */}
        <Tooltip
          formatter={(value) =>
            unit == "m3"
              ? t("number", { val: value })
              : typeof value == "number" && +value?.toFixed(0)
          }
        />{" "}
        //
      </LineChart>
    </Box>
  );
};

export default MeterReadingsGraph;
