import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/it";

const BalanceDayDatePicker = ({ props }) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1); // Устанавливаем дату на один день назад

  yesterday.setUTCHours(0, 0, 0, 0);
  const endOfYerstaday = new Date();
  endOfYerstaday.setDate(endOfYerstaday.getDate() - 1);

  const isoYesterday = yesterday.toISOString();

  const [date, setDate] = useState({ ...props.formData });
  const [startDate, setStartDate] = useState(isoYesterday);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [endDate, setEndDate] = useState(endOfYerstaday);
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];
  let StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];
  const newFrom = new Date(startDate);
  const [firstRender, setFirstRender] = useState(true);
  const newTo = new Date(endDate);
  const invalidStartDate = newFrom == "Invalid Date" ? "Invalid Date" : null;
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;

  useEffect(() => {
    if (props?.schema?.mode == "day") {
      endOfYerstaday.setUTCHours(23, 59, 59, 59);
      const isoEndOfYerstaday = endOfYerstaday.toISOString();

      props.onChange({
        from: isoYesterday,
        to: isoEndOfYerstaday,
      });
      setFirstRender(false);
    }
  }, [props?.schema?.mode]);

  useEffect(() => {
    if (
      newFrom != "Invalid Date" &&
      startDate !== null &&
      newTo !== "Invalid Date" &&
      newTo !== null &&
      !firstRender
    ) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const from = Date.UTC(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
      );

      const fromDate = new Date(from);

      const to = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

      const toDate = new Date(to);
      toDate.setUTCHours(23, 59, 59, 59);
      props.onChange({
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    console.log("datePROPS", yesterday);
  }, [yesterday]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
                helperText={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
              />
            )}
            label={t("start_date")}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%", ml: 1 }}
                {...props}
                error={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
                helperText={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
              />
            )}
            label={t("end_date")}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default BalanceDayDatePicker;
