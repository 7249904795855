import { useParams } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { notesActions } from "../../redux/slices/notesSlice";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import theme from "../../theme";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CreateNote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gateway = useSelector((state) => state?.gatewaySlice?.gateway);
  const [createdId, setCreatedId] = useState(null);
  const [formData, setFormData] = useState({});
  const [createNote] = store.useCreateNoteMutation();
  const { id } = useParams();
  const { t } = useTranslation();
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      note: {
        $id: "1",
        type: "string",
        title: t("note"),
      },
    },
    required: ["note"],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    note: {
      "ui:widget": "textarea",
    },
  };
  const onSubmitClick = () => {
    const attributes = {
      device_id: id,
      note: formData.note,
      meta: { key: "value" },
    };
    createNote(attributes)
      .unwrap()
      .then((data) => setCreatedId(data.data.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  useEffect(() => {
    if (createdId) {
      navigate(`/gateways/${id}/note/${createdId}`);
    }
  }, [createdId]);
  useEffect(() => {
    dispatch(notesActions.cleanNote());
  }, []);
  return (
    <>
      <Typography sx={{ color: theme.palette.primary.main }} variant="h3">{`${t(
        "gateway"
      )}: ${gateway?.serial_number}`}</Typography>
      <Typography
        sx={{ color: theme.palette.primary.main, mb: 2, mt: 2 }}
        variant="h4"
      >
        {t("create_note")}
      </Typography>
      <Form
        schema={schema}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={onSubmitClick}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        /*   customValidate={customValidate} */
        noHtml5Validate
      />
    </>
  );
};
export default CreateNote;
