import IwdDataGrid from "../../pages/IwdDataGrid";
import IconButton from "@mui/material/IconButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useDispatch, useSelector } from "react-redux";
import ModalDialog from "../meters/ModalDialog";
import { Box, Grid, Button, Typography } from "@mui/material";
import { typeTransform } from "../../utils/utilsFunctions";
import CopyTextButton from "./CopyTextButton";
import { useEffect, useState } from "react";
import { version } from "os";
import { actions } from "../../redux/slices/authSlice";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Card from "@mui/material/Card";

const WhiteList = () => {
  const list = useSelector((state) => state?.gatewaySlice?.whiteList);
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(false);
  const [maskPage, setMaskPage] = useState(1);
  const [unmaskPage, setUnmaskPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const isMasked = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (isMasked(obj[key])) return true;
      } else if (typeof obj[key] === "string" && obj[key].includes("FF")) {
        return true;
      }
    }
    return false;
  };

  const splitData = (data) => {
    return data?.reduce(
      (acc, obj) => {
        if (isMasked(obj)) {
          acc.masked.push(obj);
        } else {
          acc.unmasked.push(obj);
        }
        return acc;
      },
      { masked: [], unmasked: [] }
    );
  };
  const dataList = list ?? [];
  const { masked, unmasked } = splitData(dataList);
  const onMailClick = (msg) => {
    setMessage(msg);
    setModalMessage(true);
  };

  const maskColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true,
      flex: 1,
      hide: true,
    },
    {
      field: "manufacturer",
      headerName: t("manufacturer"),
      type: "number",

      headerAlign: "left",
      flex: 2,
    },
    {
      field: "version",
      headerName: t("version"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "type",
      headerName: t("type"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "serial",
      headerName: t("serial"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {params.value && (
              <IconButton onClick={() => onMailClick(params.value)}>
                <DraftsIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  const maskRows = masked?.map((item, i) => ({
    id: i,
    serial: item?.address,
    manufacturer: item?.manufacturer,
    type: item?.type,
    version: item?.version,
    actions: item?.raw,
  }));

  const unmaskColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: true,
      flex: 1,
      hide: true,
    },
    {
      field: "manufacturer",
      headerName: t("manufacturer"),
      type: "number",

      headerAlign: "left",
      flex: 2,
    },
    {
      field: "version",
      headerName: t("version"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "type",
      headerName: t("type"),
      type: "number",
      sortable: true,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "serial",
      headerName: t("serial"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,

      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {params.value && (
              <IconButton onClick={() => onMailClick(params.value)}>
                <DraftsIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    console.log("masced", masked);
  }, [masked]);

  const unmaskedRows = unmasked?.map((item, i) => ({
    id: i,
    serial: item?.address,
    manufacturer: item?.manufacturer,
    type: item?.type,
    version: item?.version,
    actions: item?.raw,
  }));

  return (
    <>
      <ModalDialog
        open={modalMessage}
        close={() => setModalMessage(false)}
        title={"Message"}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",

                padding: "9px",
              }}
            >
              {JSON.stringify(message, null, 10)}
            </pre>
          </Typography>
          <CopyTextButton text={message?.raw ?? message} />
        </Box>
      </ModalDialog>
      <Box sx={{ mt: 2, display: "flex" }}>
        <Box sx={{ width: "48%" }}>
          <Typography
            sx={{ mb: 2, color: theme.palette.primary.main }}
            textAlign={"center"}
            variant="h5"
          >
            {t("filtro")}
          </Typography>
          <IwdDataGrid
            columns={maskColumns}
            perPage={perPage}
            setPage={setMaskPage}
            rows={maskRows}
            page={maskPage}
            count={masked?.length}
            sort={false}
            height={500}
            paginationMode={"cllient"}
          />
        </Box>
        <Box sx={{ width: "48%", ml: 5 }}>
          <Typography
            sx={{ mb: 2, color: theme.palette.primary.main }}
            textAlign={"center"}
            variant="h5"
          >
            {t("devices")}
          </Typography>
          <IwdDataGrid
            columns={unmaskColumns}
            perPage={perPage}
            setPage={setUnmaskPage}
            rows={unmaskedRows}
            page={unmaskPage}
            count={unmasked?.length}
            sort={false}
            height={500}
            paginationMode={"cllient"}
          />
        </Box>
      </Box>
    </>
  );
};
export default WhiteList;
