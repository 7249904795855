import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { store } from "../../redux/store";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import DoneIcon from "@mui/icons-material/Done";
import { setPreferenceDashboardId } from "../../redux/slices/userPreferenceSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardId } from "../../redux/slices/dashboardSlice";
import { useTranslation } from "react-i18next";
import theme from "../../theme";

const DashBoardSelect = ({ disabled, reset = false }) => {
  const [setPreference, { isSuccess, isLoading: preferenceLoading }] =
    store.useSetPreferenceMutation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getDashboardList, res] = store.useLazyGetDashboardListQuery();
  const dashboardId = useSelector((state) => state.dashboardSlice.dashboardId);
  const [value, setValue] = React.useState(dashboardId);

  const dashboardList = useSelector(
    (state) => state?.dashboardSlice?.dashboards
  );
  useEffect(() => {
    if (dashboardList.length < 1) {
      getDashboardList();
    }
  }, []);

  useEffect(() => {
    if (dashboardList?.length > 0 && !dashboardId) {
      setValue(dashboardList?.[0]?.id);
    }
  }, [dashboardList]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    console.log("dashval", value);
  }, [value]);
  useEffect(() => {
    if (value && !reset) {
      dispatch(setDashboardId(value));
    }
  }, [value]);
  const setNewPreferenceDashboard = () => {
    dispatch(setPreferenceDashboardId(value));
  };

  return (
    <Box sx={{ width: 400, padding: 0, display: "flex" }}>
      <FormControl fullWidth size="small">
        {/*    <InputLabel id="demo-simple-select-label">
          {t("select_dashboard")}
        </InputLabel> */}
        <Select
          disabled={disabled}
          sx={{ height: 56 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          /*  value={age} */
          value={value}
          /*    label={t("select_dashboard")} */
          onChange={handleChange}
        >
          {dashboardList?.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {reset && (
        <IconButton onClick={setNewPreferenceDashboard}>
          <DoneIcon sx={{ color: theme.palette.success.main }} />
        </IconButton>
      )}
    </Box>
  );
};

export default DashBoardSelect;
