import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Button, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import { setUserName } from "../../redux/slices/breadCrumbMeterSlice";
import TrashModal from "../TrashModal";
import { store } from "../../redux/store";
import theme from "../../theme";

const Users = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [trashId, setTrashId] = useState(null);
  const users = useSelector((state) => state?.userSlice?.users);
  const rowCount = useSelector((state) => state?.userSlice?.count);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const { t } = useTranslation();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const paramsList = {
    page: page,
    per_page: perPage,
  };
  useEffect(() => {
    getUsers(paramsList);
  }, [page, deleteSuccess]);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setUserName(null));
  }, []);
  useEffect(() => {
    console.log("users", users);
  }, [users]);
  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(trashId);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  const onTrashClick = (id) => {
    dispatch(opendModal());
    setTrashId(id);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "first_name",
      headerName: t("first_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "last_name",
      headerName: t("last_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: t("email"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "user_name",
      headerName: t("user_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "display_name",
      headerName: t("display_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "nick_name",
      headerName: t("nick_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <Tooltip title={t("delete_user_title")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
            <IconButton sx={{ float: "right" }}>
              <Link to={`/users/${params?.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = users?.map((item, index) => ({
    id: index,
    first_name: item?.attributes?.first_name ?? "--",
    last_name: item?.attributes?.last_name ?? "--",
    email: item?.attributes?.email ?? "--",
    user_name: item?.attributes?.user_name ?? "--",
    display_name: item?.attributes?.display_name,
    nick_name: item?.attributes?.nick_name ?? "--",
    actions: item?.id,
  }));
  return (
    <>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h2"
      >
        {t("users")}
      </Typography>
      <TrashModal title={t("delete_user_title")} text={t("delete_user_text")} />
      {tableRows && (
        <Box sx={{ height: "60vh" }}>
          <StyledDataGrid
            disableColumnMenu
            rows={tableRows}
            columns={columns}
            rowCount={rowCount}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            // loading={isLoadingTable}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      )}
      <IwdAuthWrapper
        children={
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
              }}
              onClick={() => navigate("/user/create")}
            >
              {t("new_user")}
            </Button>
          </Box>
        }
        section={"add_user_btn"}
      />
    </>
  );
};

export default Users;
