import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import { Box } from "@mui/material";

const FormAccordion = (props) => {
  return (
    <Box sx={{ width: props.width ?? "96.3%", mt: 3 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          onClick={props.onClick}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{props.title ?? "Search Form"}</Typography>
        </AccordionSummary>
        <Box sx={{ ml: 2, mr: 2, mb: 10 }}>{props.children}</Box>
      </Accordion>
    </Box>
  );
};

export default FormAccordion;
