import { createSlice, current } from "@reduxjs/toolkit";

import { actions } from "./authSlice";
import { store } from "../store";
import { removeDuplicateObjects } from "../../utils/api_params";
import { setGateway } from "./gatewaySlice";
import { findMaxPosition } from "../../utils/utilsFunctions";

const initialState = {
  condominiums: null,
  condominiumShow: null,
  metaCount: null,
  condominiumTreeData: null,
  condominiumId: null,
  subCondominiumIdentifier: null,
  subCondominiumId: null,
  selectedTreeItem: "condominum",
  btnDetector: 1,
  apartamentMeterModal: false,
  subCondominiumGatewayModal: false,
  apartamentIdentifier: null,
  condominiumReadings: [],
  condominiumReadingsCount: null,
  subcondominiumMeter: null,
  subCondominiumReadings: null,
  subCondominiumReadingsCount: null,
  apartamentReadings: null,
  apartamaentReadingsCount: null,
  condominiumConsumptions: null,
  condominiumConsumptionsCount: null,
  subCondomimiumConsumptions: null,
  subCondomimiumConsumptionsCount: null,
  apartamentConsumtions: null,
  apartamentConsumtionsCount: null,
  treeExpanded: sessionStorage.getItem("TreeExpanded")
    ? sessionStorage.getItem("TreeExpanded")?.split(",")
    : ["condominum"],
  subCondominiumName: null,
  apartamentName: null,
  condominiumTabValue: 0,
  firstRender: true,
  condominiumName: null,
  condominiumIdentifier: null,
  apartamentId: null,
  meterId: null,
  condominumMeter: null,
  apartamentMeter: null,
  maxSubCondominumPos: null,
  maAxppPos: null,
  actualSubConPos: null,
  topSubPos: null,
  botSubPos: null,
  topSubId: null,
  botSubid: null,
  topAppPos: null,
  botAppPos: null,
  actualAppPos: null,
  subCondominiumIndex: null,
  apartamentIndex: null,
  subTopElementMeter: null,
  subBotElementMeter: null,
  posDirection: null,
  subCondominiumTopAllowed: null,
  subCondominiumBotAllowed: null,
  appTopAllowed: null,
  appBotAllowed: null,
  importInfo: [],
  condominiumTrashId: null,
  apartamentShowMode: null,
  associatedUsers: null,
  usersStatus: null,
  lockSuccess: false,
  unlockSuccess: false,
  freeMeters: null,
  freeMeterCount: null,
  newDeviceId: null,
  newDeviceSerial: null,
  gatewayMode: false,
  stats: null,
  totalWater: null,
  totalHca: null,
  totalHeat: null,
  createdMeter: null,
  meterDissocId: null,
  meterIdentifier: null,
  calendarData: null,
  readingTableTab: null,
  createdMeterSerial: null,
  newReplacedSerial: null,
  blockTabName: null,
};

const condominiumSlice = createSlice({
  name: "condominiumSlice",
  initialState,
  reducers: {
    setTreeItem(state, action) {
      if (action.payload !== "new") {
        state.selectedTreeItem = action.payload;
      }
    },
    setBtnDetector(state, action) {
      state.btnDetector = action.payload;
    },
    setSubCondominiumIdentifier(state, action) {
      state.subCondominiumIdentifier = action.payload;
    },
    setApartametnMeterModal(state, action) {
      state.apartamentMeterModal = action.payload;
    },
    setSubCondominiumGatewayModal(state, action) {
      state.subCondominiumGatewayModal = action.payload;
    },
    setApartamentIdentifier(state, action) {
      state.apartamentIdentifier = action.payload;
    },
    setAssociatedusers(state, action) {
      state.associatedUsers = action.payload;
    },
    setTreeExpanded(state, action) {
      const tree = action.payload;
      state.treeExpanded = [...new Set(tree)];
    },
    setCondomiumShow(state, action) {
      state.condominiumShow = action.payload;
    },
    setCondominiumId(state, action) {
      state.condominiumId = action.payload;
    },
    cleanData(state) {
      state.condominiumId = null;
      state.apartamentIdentifier = null;
      state.subCondominiumIdentifier = null;
      state.actualAppPos = null;
      state.maAxppPos = null;
      state.apartamentIndex = null;
      state.maxSubCondominumPos = null;
      state.actualSubConPos = null;
      state.subCondominiumIndex = null;
    },
    setSubConName(state, action) {
      state.subCondominiumName = action.payload;
    },
    setApartamentName(state, action) {
      state.apartamentName = action.payload;
    },
    setCondomiumTabValue(state, action) {
      state.condominiumTabValue = action.payload;
    },
    setPosDirection(state, action) {
      state.posDirection = action.payload;
    },
    setMaxAppPos(state, action) {
      state.maAxppPos = action.payload;
    },
    setActualSubConPos(state, action) {
      state.actualSubConPos = action.payload;
    },
    setActualAppPos(state, action) {
      state.actualAppPos = action.payload;
    },
    offFirstRender(state) {
      state.firstRender = false;
    },
    setSubComIndex(state, action) {
      state.subCondominiumIndex = action.payload;
    },
    setAppIndex(state, action) {
      state.apartamentIndex = action.payload;
    },
    cleanMeterId(state) {
      state.meterId = null;
    },
    setSubCondominiumTopAllowed(state, action) {
      state.subCondominiumTopAllowed = action.payload;
    },
    setSubCondominiumBotAllowed(state, action) {
      state.subCondominiumBotAllowed = action.payload;
    },
    setAppTopAllowed(state, action) {
      state.appTopAllowed = action.payload;
    },
    setAppBotAllowed(state, action) {
      state.appBotAllowed = action.payload;
    },
    setMaxSubconPos(state, action) {
      state.maxSubCondominumPos = action.payload;
    },
    setCondominiumImportInfo(state, action) {
      /* state.importInfo.push(action.payload); */

      let endMessage = action.payload?.filter(
        (item) => item.status == "end"
      )?.[0];
      const data = [...current(state.importInfo), ...action.payload];
      const uniqData = removeDuplicateObjects(data, (item) => item?.name);
      state.importInfo = [...uniqData /*  endMessage && endMessage */];
    },
    setcondominiumTrashId(state, action) {
      state.condominiumTrashId = action.payload;
    },
    setCondominiumReadings(state, action) {
      state.condominiumReadings = action.payload;
    },
    resetSubcondominiumReadings(state, action) {
      state.subCondominiumReadings = null;
      console.log("zamutiltemku");
    },
    resetApartmentReadings(state, action) {
      state.apartamentReadings = null;
    },
    resetCondominiumReadings(state, action) {
      state.condominiumReadings = [];
    },
    resetConsuptions(state, action) {
      state.condominiumConsumptions = null;
      state.condominiumConsumptionsCount = null;
      state.subCondomimiumConsumptions = null;
      state.subCondomimiumConsumptionsCount = null;
      state.apartamentConsumtions = null;
      state.apartamentConsumtionsCount = null;
    },
    clearCondominiumImportInfo(state) {
      state.importInfo = [];
    },
    setShowMode(state, action) {
      state.apartamentShowMode = action.payload;
    },
    resetMeterId(state) {
      state.meterId = null;
    },
    setLockSuccess(state, action) {
      state.lockSuccess = action.payload;
    },
    setUnlockSuccess(state, action) {
      state.unlockSuccess = action.payload;
    },
    resetUserStatus(state) {
      state.usersStatus = null;
    },
    setSubElemPositions(state, action) {
      state.botSubPos = action.payload.bot;
      state.topSubPos = action.payload.top;
      state.topSubId = action.payload.topId;
      state.botSubid = action.payload.botId;
    },
    setAppElemPositions(state, action) {
      state.botAppPos = action.payload.bot;
      state.topAppPos = action.payload.top;
    },
    setNewDeviceId(state, action) {
      state.newDeviceId = action.payload.id;
      state.newDeviceSerial = action.payload.serial;
    },
    cleanFreeData(state, action) {
      state.freeMeterCount = null;
      state.freeMeters = null;
    },
    setGatewayMode(state, action) {
      state.gatewayMode = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setCreatedMeter(state, action) {
      state.createdMeter = action.payload;
    },
    setMeterDissocId(state, action) {
      state.meterDissocId = action.payload;
    },
    setMeterIdentifier(state, action) {
      state.meterIdentifier = action.payload;
    },
    setReadingTableTab(state, action) {
      state.readingTableTab = action.payload;
    },
    resetBlockStatistics(state, action) {
      state.stats = null;
      state.totalHca = null;
      state.totalHeat = null;
      state.totalWater = null;
    },
    setCreatedMeterSerial(state, action) {
      state.createdMeterSerial = action.payload;
    },
    setNewReplacedSerial(state, action) {
      state.newReplacedSerial = action.payload;
    },
    setBlockTabName(state, action) {
      state.blockTabName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.condominiumEndpoints.condominiumIndex.matchFulfilled,
      (state, action) => {
        state.condominiums = action.payload.data;
        state.metaCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.condominiumShow.matchFulfilled,
      (state, action) => {
        state.condominiumShow = action.payload.data.attributes;
        state.condominiumTreeData = action.payload.data;
        state.condominumMeter = action.payload.data;
        state.condominiumIdentifier = action.payload.data.attributes.identifier;
        state.condominiumName = action.payload.data.attributes.name;
        let maxSubPos = findMaxPosition(
          action.payload.data.attributes.children
        );
        state.maxSubCondominumPos = maxSubPos;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumId.matchFulfilled,
      (state, action) => {
        state.condominiumId = action.payload.data.id;
        state.condominumMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumId.matchFulfilled,
      (state, action) => {
        state.subCondominiumId = action.payload.data.id;
        state.subcondominiumMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentId.matchFulfilled,
      (state, action) => {
        state.apartamentId = action.payload.data.id;
        state.apartamentMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumReadings.matchFulfilled,
      (state, action) => {
        state.condominiumReadings = [
          ...state.condominiumReadings,
          ...action.payload.data?.map((item) => ({
            ...item,
            attributes: {
              ...item.attributes,
              values: {
                ...item.attributes.values,
                serial_number: item.attributes.values.serial_number,
              },
            },
          })),
        ]?.reduce((uniqueReadings, item) => {
          const existingReading = uniqueReadings?.find(
            (reading) =>
              reading?.attributes?.values?.serial_number ===
              item?.attributes?.values?.serial_number
          );

          if (!existingReading) {
            uniqueReadings.push(item);
          }
          console.log("vafled", uniqueReadings);
          return uniqueReadings;
        }, []);

        state.condominiumReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumReadings.matchFulfilled,
      (state, action) => {
        state.subCondominiumReadings = action.payload.data?.reduce(
          (uniqueReadings, item) => {
            const existingReading = uniqueReadings?.find(
              (reading) =>
                reading?.attributes?.values?.serial_number ===
                item?.attributes?.values?.serial_number
            );

            if (!existingReading) {
              uniqueReadings.push(item);
            }
            console.log("vafled", uniqueReadings);
            return uniqueReadings;
          },
          []
        );
        state.subCondominiumReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentReadings.matchFulfilled,
      (state, action) => {
        state.apartamentReadings = action.payload.data?.reduce(
          (uniqueReadings, item) => {
            const existingReading = uniqueReadings?.find(
              (reading) =>
                reading?.attributes?.values?.serial_number ===
                item?.attributes?.values?.serial_number
            );

            if (!existingReading) {
              uniqueReadings.push(item);
            }
            console.log("vafled", uniqueReadings);
            return uniqueReadings;
          },
          []
        );
        state.apartamaentReadingsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCondominiumConsumptions.matchFulfilled,
      (state, action) => {
        state.condominiumConsumptions = action.payload.data.attributes.data;
        state.condominiumConsumptionsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubcondominiumConsumtions.matchFulfilled,
      (state, action) => {
        state.subCondomimiumConsumptions = action.payload.data.attributes.data;
        state.subCondomimiumConsumptionsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getApartamentConsumtions.matchFulfilled,
      (state, action) => {
        state.apartamentConsumtions = action.payload.data.attributes.data;
        state.apartamaentReadingsCount =
          action.payload.data.attributes.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.createApartment.matchFulfilled,
      (state, action) => {
        state.subCondominiumId = null;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getMetertId.matchFulfilled,
      (state, action) => {
        state.meterId = action.payload.data.id;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumBotElement.matchFulfilled,
      (state, action) => {
        state.subBotElementMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getSubCondominiumTopElement.matchFulfilled,
      (state, action) => {
        state.subTopElementMeter = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getAssociatedUsers.matchFulfilled,
      (state, action) => {
        state.associatedUsers = action.payload.data;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getUsersStatus.matchFulfilled,
      (state, action) => {
        if (action.payload.data.attributes.users_locked == undefined) {
          state.usersStatus = false;
        } else {
          state.usersStatus = action.payload.data.attributes.users_locked;
        }
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.unlockUsers.matchFulfilled,
      (state, action) => {
        state.unlockSuccess = true;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.lockUsers.matchFulfilled,
      (state, action) => {
        state.lockSuccess = true;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getFreeMeters.matchFulfilled,
      (state, action) => {
        state.freeMeters = action.payload.data;
        state.freeMeterCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getBlockStats.matchFulfilled,
      (state, action) => {
        state.stats = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getCalendatValues.matchFulfilled,
      (state, action) => {
        state.calendarData = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.condominiumEndpoints.getTotalReadings.matchFulfilled,
      (state, action) => {
        const water = action.payload.data.attributes?.filter(
          (item) => item.type == "07"
        )?.[0];
        const totalWater = (water?.reading_number * 100) / water?.tot;
        const heat = action.payload.data.attributes?.filter(
          (item) => item.type == "13"
        )?.[0];
        const totalHeat = (heat?.reading_number * 100) / heat?.tot;
        const hca = action.payload.data.attributes?.filter(
          (item) => item.type == "08"
        )?.[0];
        const totalHca = (hca?.reading_number * 100) / hca?.tot;
        state.totalWater = totalWater ?? null;
        state.totalHeat = totalHeat ?? null;
        state.totalHca = totalHca ?? null;
      }
    );
  },
});

export const {
  setTreeItem,
  setBtnDetector,
  setSubCondominiumIdentifier,
  setApartametnMeterModal,
  setApartamentIdentifier,
  setTreeExpanded,
  setCondomiumShow,
  setSubCondominiumTopAllowed,
  setSubCondominiumBotAllowed,
  setMeterIdentifier,
  cleanData,
  setApartamentName,
  setSubConName,
  setCondomiumTabValue,
  setSubCondominiumGatewayModal,
  offFirstRender,
  cleanMeterId,
  setMaxAppPos,
  setActualAppPos,
  setActualSubConPos,
  setSubComIndex,
  setPosDirection,
  setAppIndex,
  setAppTopAllowed,
  setAppBotAllowed,
  setCondominiumImportInfo,
  clearCondominiumImportInfo,
  setcondominiumTrashId,
  setCondominiumReadings,
  resetApartmentReadings,
  resetSubcondominiumReadings,
  resetCondominiumReadings,
  setCondominiumId,
  setCreatedMeterSerial,
  setShowMode,
  setAssociatedusers,
  setLockSuccess,
  setUnlockSuccess,
  resetUserStatus,
  resetConsuptions,
  setSubElemPositions,
  setAppElemPositions,
  cleanFreeData,
  setNewDeviceId,
  setCreatedMeter,
  setGatewayMode,
  setStats,
  setMaxSubconPos,
  setMeterDissocId,
  setReadingTableTab,
  resetBlockStatistics,
  setNewReplacedSerial,
  setBlockTabName,
} = condominiumSlice.actions;
export default condominiumSlice.reducer;
