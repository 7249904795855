import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";
import { resetIndex } from "../../redux/slices/gatewaySlice";
import FormAccordion from "../form/formComponents/Accordion";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { cleanGatewayMessages } from "../../redux/slices/gatewaySlice";
import { unSetClusterId } from "../../redux/slices/clusterSlice";
import theme from "../../theme";
import Button from "@mui/material/Button";
import { store } from "../../redux/store";
import { setGateway } from "../../redux/slices/gatewaySlice";
import IwdDataGrid from "../../pages/IwdDataGrid";

import { Box } from "@mui/system";
import GatewayIndexTable from "./GatewayIndexTable";
import {
  Tabs,
  Tab,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
} from "@mui/material";
import MapDevice from "../form/formComponents/MapDevice";
import GatewayIndexForm from "./GatewayIndexFrom";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const GatewayIndex = ({ fromCondominium = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [field, setField] = useState("");
  const [order, setOrder] = useState("");
  const [page, setPage] = useState(1);
  const [iconHover, setIconHover] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const selectedClusterId = useSelector(
    (state) => state?.clusterSlice?.selectedClusterId
  );
  const [getPreference] = store.useLazyGetPreferenceQuery();
  let [searchParams, setSearchParams] = useSearchParams();
  const [preferenceLoading, setPreferenceLoading] = useState(false);
  const [params, setParams] = useState({});
  const [hideAccordion, setHideAccordion] = useState(false);
  const [value, setValue] = useState(0);
  const gateways = useSelector((state) => state?.gatewaySlice?.gataways);
  const devices = useSelector((state) => state?.clusterSlice?.devices);
  const userPreferenceFirstRender = useSelector(
    (state) => state?.userPreferenceSlice?.firstRender
  );
  const onAccordionClick = () => {
    setHideAccordion(!hideAccordion);
  };
  const paramsList = {
    serial_number: searchParams.get("serial_number"),
    name: searchParams.get("name"),
    modem_imei: searchParams.get("modem_imei"),
    inserted_at: searchParams.get("inserted_at"),
    page: page,
    per_page: perPage,
    class: "Gateway",
    field: field,
    order: order,
    gateway_not_seen: searchParams.get("gateway_not_seen"),
  };
  useEffect(() => {
    dispatch(setGateway(null));
    dispatch(resetIndex());
  }, []);
  const handleChange = (event, newValue) => {
    if (newValue !== 2) {
      setValue(newValue);
    }
  };
  useEffect(() => {
    if (userPreferenceFirstRender) {
      getPreference();
    }
  }, []);
  const onIconHover = () => {
    setIconHover(!iconHover);
  };
  useEffect(() => {
    dispatch(cleanGatewayMessages());
  }, []);
  useEffect(() => {
    console.log("order", order);
  }, [order]);
  const onCancellClick = () => {
    dispatch(unSetClusterId());
    setIconHover(true);
  };
  /*   useEffect(() => {
    window.sessionStorage.setItem("bcName", "Concentratori");
    return () => {
      window.sessionStorage.removeItem("bcName");
    };
  }, []); */
  return (
    <>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h2"
      >
        {t("concentratori")}
      </Typography>
      <FormAccordion onClick={onAccordionClick} width={"97.4%"}>
        <GatewayIndexForm
          paramsList={{ ...paramsList }}
          fromCondominium={fromCondominium}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setParams={setParams}
        />
      </FormAccordion>

      <Box sx={{ mt: 2 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t("table")} />
          <Tab label={t("map")} />
          {selectedClusterId !== "" && (
            <Tab
              label={
                iconHover ? (
                  <CheckCircleOutlineIcon sx={{ fill: "green" }} />
                ) : (
                  <Tooltip title="unset cluster">
                    <HighlightOffIcon sx={{ fill: "red" }} />
                  </Tooltip>
                )
              }
              onMouseEnter={onIconHover}
              onMouseLeave={onIconHover}
              onClick={onCancellClick}
            />
          )}
          {preferenceLoading && (
            <Tab
              label={
                <Tooltip title="saving user preference">
                  <SaveIcon sx={{ fill: "green" }} />
                </Tooltip>
              }
            />
          )}
        </Tabs>
        <TabPanel value={value} index={0}>
          <GatewayIndexTable
            fromCondominium={fromCondominium}
            data={devices?.data ? devices?.data : gateways}
            page={page}
            perPage={perPage}
            setField={setField}
            setOrder={setOrder}
            paramsList={{ ...paramsList }}
            setPage={setPage}
            hideAccordion={hideAccordion}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapDevice
            hideAccordion={hideAccordion}
            setPreferenceLoading={setPreferenceLoading}
            classValue={"Gateway"}
            params={params}
          />
        </TabPanel>
        {!fromCondominium && (
          <Button
            onClick={() => navigate(`/gateways/create`)}
            sx={{
              float: "right",
              mr: 5,
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
            variant="contained"
          >
            {t("new_gateway")}
          </Button>
        )}
      </Box>
    </>
  );
};
export default GatewayIndex;
