import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Chip, Divider } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import PopoverCardTitle from "../PopoverCardTitle";

const BlockWidget = ({ value, icon, title, tooltip }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={5}
      sx={{
        maxWidth: 300,
        height: 285,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        zIndex: 9999,
      }}
    >
      <Typography
        sx={{ mt: 1, mb: 1 }}
        variant="subtitle1"
        textAlign={"center"}
      >
        {title}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Divider />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Typography variant="h2" color={theme?.palette.primary.main}>
          {value}
        </Typography>
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          <Grid item xs={4}>
            <Tooltip title={tooltip}>
              <Chip label={icon} />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BlockWidget;
