import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import WorklistPolygonList from "./WorklistPolygonList";
import Button from "@mui/material/Button";
import { store } from "../../redux/store";
import WorkListClassSelector from "./WorkListClassSelector";

import { worklistActions } from "../../redux/slices/worklistSlice";
import { Grid } from "@mui/material";
import { setMessage, setSeverity } from "../../redux/slices/snackbarSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const WorkListEditorCreateForm = ({ tabValue, file }) => {
  const preparePolygonBody = ({ positions, id }) => {
    return {
      data: {
        attributes: {
          geom: {
            coordinates: [[...positions, positions[0]]],
            type: "Polygon",
          },
        },
        work_list_editor_id: id,
        type: "plguin",
      },
    };
  };
  const dispatch = useDispatch();
  const bearer = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const polygons = useSelector((state) => state?.worklistSlice?.polygons);
  const [createWorklistEditor] = store.useCreateWorklistEditorMutation();
  const fileData = new FormData();
  const [createPolygon, { isSuccess: createPolygonSuccess }] =
    store.useCreateWorklistPolygonMutation();
  const [worklistId, setWorklistId] = useState(null);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  /*   const uploadFile = () => {
    console.log(formData);
    const body = {
      operation_id: uuid,
      file: formData,
    };
    fetch(`${window.BASE_URL}/api/v1/altior/device/upload`, {
      method: "POST",
      body: formData,
   
      headers: {
      
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOperationStatus(data.operation_status))
      .catch((err) => console.error(err));
  }; */
  const onSubmitClick = () => {
    if (tabValue == 0) {
      const attributes = {
        name: formData.name,
        description: formData.description,
        classes: [...formData.class],
      };
      createWorklistEditor(attributes)
        .unwrap()
        .then((data) => setWorklistId(+data.data.attributes?.id))
        .catch((er) => console.log("err", er));
    }
    if (tabValue == 1) {
      fileData.append("csv_file", file);
      fileData.append("name", formData.name);
      fileData.append("description", formData.description);
      fetch(`${window.BASE_URL}/api/v1/plugin/worklisteditor`, {
        method: "POST",
        body: fileData,

        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setWorklistId(data?.data?.attributes?.id))
        .catch((err) => {
          dispatch(setSeverity("error"));
          dispatch(setMessage(t("failed_file_upload")));
        });
    }
  };
  useEffect(() => {
    if (tabValue == 1 && worklistId) {
      navigate(`/worklisteditors/${worklistId}`);
    }
  }, [worklistId]);
  useEffect(() => {
    if (worklistId && polygons.length > 0) {
      for (let i = 0, len = polygons.length; i < len; i++) {
        createPolygon({ attributes: polygons[i], id: worklistId });
      }
    }
  }, [worklistId, polygons]);
  useEffect(() => {
    console.log("worklistId", worklistId);
  }, [worklistId]);
  useEffect(() => {
    if (createPolygonSuccess) {
      navigate(`/worklisteditors/${worklistId}`);
    }
  }, [createPolygonSuccess]);
  useEffect(() => {
    console.log("polygs", polygons);
  }, [polygons]);
  useEffect(() => {
    dispatch(worklistActions.updatingOf());
  }, []);
  const schema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
      },
      class: {
        id: 0,
        type: "string",
        title: t("Tipologia"),
        multiple: true,
        url: "altior/digital_twin",
        type: "string",
      },
    },
    required: ["name", "description"],
  };
  const fileSchema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
      },
    },
    required: ["name", "description"],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    class: {
      "ui:field": "search",
    },
  };
  const fields = {
    search: WorkListClassSelector,
  };

  return (
    <>
      <Form
        schema={tabValue == 0 ? schema : fileSchema}
        fields={fields}
        formData={formData}
        /*     customValidate={customValidate} */
        showErrorList={false}
        children={true}
        /* onSubmit={onSubmitClick} */
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
        validator={validator}
        uiSchema={uiSchema}
      />
      <WorklistPolygonList />
      <Button
        onClick={onSubmitClick}
        variant="outlined"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: "white",
          mt: 2,
          "&:hover": { backgroundColor: theme.palette.primary.main },
        }}
      >
        {t("create")}
      </Button>
    </>
  );
};
export default WorkListEditorCreateForm;
