import { useDispatch, useSelector } from "react-redux";
import { hasCommonValue } from "../utils/api_params";

export const IwdAuthWrapper = ({ section, children }) => {
  const permessions = window?.COMPONENTS?.[section];
  const groups = useSelector((state) => state?.userPreferenceSlice.groups);
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const render = () => {
    if (permessions?.groups?.length > 0) {
      if (hasCommonValue(groups, permessions?.groups)) {
        return true;
      } else {
        return false;
      }
    }
    if (permessions?.groups?.length == 0 || !permessions?.groups) {
      if (hasCommonValue(roles, permessions?.roles)) {
        return true;
      } else {
        return false;
      }
    }
  };
  return <>{permessions?.visibility && render() ? <>{children}</> : null}</>;
};
