import { createSlice } from "@reduxjs/toolkit";
import { gatewayApi } from "./gatewayApi";
import { store } from "../store";
const initialState = {
  favId: null,
  logoId: null,
  configShow: null,
  configIndex: null,
  configCount: null,
  configTrashId: null,
};

const configurationSlice = createSlice({
  name: "configurationSlice",
  initialState,
  reducers: {
    setConfigTrashId(state, action) {
      state.configTrashId = action.payload;
    },
    setConfigShow(state, action) {
      state.configShow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.configurationEndpoints.getShowConfiguration.matchFulfilled,
      (state, action) => {
        state.favId = action.payload.data.attributes?.favicon_id;
        state.logoId = action.payload.data.attributes?.logo_id;
      }
    );
    builder.addMatcher(
      store.configurationEndpoints.getConfigShow.matchFulfilled,
      (state, action) => {
        state.configShow = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.configurationEndpoints.getConfigIndex.matchFulfilled,
      (state, action) => {
        state.configIndex = action.payload.data;
        state.configCount = action.payload.meta.count;
      }
    );
  },
});
export const { setConfigTrashId, setConfigShow } = configurationSlice.actions;
export default configurationSlice.reducer;
