import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { store } from "../redux/store";
import { useAuth } from "./AuthProvider";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slices/authSlice";

const Logged = () => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(localStorage.getItem("from"));
  const preference = useSelector(
    (state) => state.userPreferenceSlice?.preference
  );
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);
  const [getMe] = store.useLazyGetPreferenceQuery();
  const currentToken = useSelector((state) => state?.authSlice?.currentToken);
  const { token } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  /*  const from = actions.getFrom() || "/"; "/metrics"; */
  /*   useEffect(() => {
    setFrom(JSON.parse(localStorage.getItem("from")));
  }, []); */
  const getMeCall = () => {
    auth.getMe(() => navigate(from ?? "/metrics", { redirect: true }));
  };
  useEffect(() => {
    if (currentToken == null) {
      dispatch(actions.signin(token));
    }
  }, [currentToken]);
  useEffect(() => {
    if (currentToken && !userInfo) {
      getMe();
    }
  }, [currentToken, userInfo]);

  useEffect(() => {
    if (preference && userInfo) {
      navigate("/", { redirect: true });
    }
  }, [preference, userInfo]);
  return <Loader />;
};

export default Logged;
