import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { errorTypeActions } from "../../redux/slices/errorTypeSlice";
import theme from "../../theme";
import { Button, Grid, Typography } from "@mui/material";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ErrorTypeShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const errorType = useSelector((state) => state?.errorTypeSlice?.errorType);
  const [getErrorType] = store.useLazyGetErrorTypeShowQuery();
  const [updateErrorType, { isSuccess: updateSuccess }] =
    store.useUpdateErrorTypeMutation();
  const { id } = useParams();
  const { t } = useTranslation();
  const schema = {
    tittle: "New type error",
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
        default: errorType?.name,
      },
    },
    required: ["name"],
  };
  useEffect(() => {
    getErrorType(id);
  }, [id, updateSuccess]);
  useEffect(() => {
    return () => {
      dispatch(errorTypeActions.showClean());
    };
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("successfully_updated")));
    }
  }, [updateSuccess]);
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
  };
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
    };
    updateErrorType({ attributes: attributes, id: id });
  };
  return (
    <>
      {errorType && (
        <>
          <Typography
            variant="h3"
            sx={{ mb: 2, color: theme.palette.primary.main }}
          >
            {t("upd_error_type")}
          </Typography>

          <Box>
            <Form
              schema={schema}
              formData={formData}
              onChange={(changeEvent) => setFormData(changeEvent.formData)}
              onSubmit={onSubmitClick}
              validator={validator}
              uiSchema={uiSchema}
              /* widgets={widgets} */
              showErrorList={false}
              /*   customValidate={customValidate} */
              noHtml5Validate
              children={true}
            />
            <Button
              variant="contained"
              disabled={!formData.name}
              onClick={onSubmitClick}
              sx={{ mt: 2, float: "right" }}
            >
              {t("update")}
            </Button>
            <Button
              onClick={() => navigate("/errortypes")}
              variant="contained"
              sx={{ mt: 2 }}
            >
              {t("back")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default ErrorTypeShow;
