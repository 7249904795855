import React, { useState, useEffect } from "react";
import useFetch from "use-http";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import DeviceShowItem from "../components/DeviceShowItem";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeviceMap from "../components/DeviceMap";
import DeviceMessage from "./DeviceMessages";
import DeviceCommandForm from "../components/DeviceCommandForm";
import DeviceCommandProvider from "../components/DeviceCommandProvider";
import { useIwdBreadCrumb } from "../components/IwdBreadcrumbProvider";
import WaterMeterShow from "../components/deviceShow/WaterMeterShow";
import GatewayShow from "../components/deviceShow/GatewayShow";
import GatewayCoverage from "../components/deviceShow/GatewayCoverage";
import WaterMeterReadings from "../components/deviceShow/WaterMeterReadings";
import WaterLeakCableShow from "../components/deviceShow/WaterLeakCableShow";
import WaterLeakCableMessages from "../components/deviceShow/WaterLeakCableMessages";
import PressureSensorShow from "../components/deviceShow/PressureSensorShow";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ShowDevice = () => {
  const { id } = useParams();
  const { loading, error, get } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const { t } = useTranslation();
  const { setLast } = useIwdBreadCrumb();
  const [digitalTwinInstance, setDigitalTwinInstance] = useState();
  const [digitalTwinAttributes, setDigitalTwinAttributes] = useState();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = React.useState(["base"]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const waterMeterClasses =
    `${process.env.REACT_APP_WATER_METER_CLASSES}`.split(" ");
  const gatewayClasses = `${process.env.REACT_APP_GATEWAY_CLASSES}`.split(" ");
  const sensorClaesses = `${process.env.REACT_APP_SENSORS_CLASSES}`.split(" ");

  const handleChangeTab = (panel) => (event, newExpanded) => {
    const index = expanded.indexOf(panel);
    var newValues = [...expanded];
    if (index >= 0) {
      delete newValues[index];
    } else {
      newValues.push(panel);
    }
    setExpanded(newValues);
  };
  const splitInCategories = (attributes) => {
    const results = {};
    attributes.forEach((item) => {
      var key = item.attributes.category;
      if (!key) {
        key = "base";
      }
      if (!results[key]) {
        results[key] = [];
      }
      results[key].push(item);
    });

    return results;
  };
  const getCategoryKeys = (categories) => {
    return Object.keys(categories)
      ?.filter((c) => c != "command")
      .sort();
  };

  useEffect(() => {
    const fetchData = async () => {
      const detail = await get(`/api/v1/altior/digital_twin/1/instance/${id}`);
      if (detail) {
        setLast(detail.data.attributes.serial_number);
      }
      setDigitalTwinInstance(detail);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const detail = await get(
        `api/v1/altior/digital_twin/${digitalTwinInstance.data.attributes.digital_twin_id}/attribute`
      );
      setDigitalTwinAttributes(detail.data);
    };
    if (digitalTwinInstance) {
      fetchData();
    }
  }, [digitalTwinInstance]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div> Some network error </div>;
  }

  var categories = {};
  if (digitalTwinInstance && digitalTwinAttributes) {
    categories = splitInCategories(digitalTwinAttributes);
  }

  return (
    <>
      <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
        {t("device")}:{" "}
        {digitalTwinInstance
          ? digitalTwinInstance.data.attributes.serial_number
          : ""}
      </Typography>
      <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
        <Box sx={{ gridRow: "1", gridColumn: "span 2", width: "100%" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t("info")} />
            <Tab label={t("commands")}></Tab>
            {/*<Tab label={t("readings")}></Tab>*/}
            <Tab label={t("messages")}></Tab>
            {digitalTwinInstance &&
            gatewayClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ? (
              <Tab label={t("coverage")}></Tab>
            ) : (
              <></>
            )}
            {digitalTwinInstance &&
            (waterMeterClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ||
              gatewayClasses.includes(
                digitalTwinInstance.data.attributes.class
              ) ||
              sensorClaesses.includes(
                digitalTwinInstance.data.attributes.class
              ) ||
              digitalTwinInstance.data.attributes.class != "WaterLeakCable") ? (
              <Tab label={t("general")}></Tab>
            ) : (
              <></>
            )}
            {/*<Tab label={t("groups")}></Tab>*/}
            {/* <Tab label={t("districts")}></Tab> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            {digitalTwinInstance &&
            gatewayClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ? (
              <GatewayShow
                device={digitalTwinInstance}
                attributes={digitalTwinAttributes}
                categories={categories}
              ></GatewayShow>
            ) : (
              <></>
            )}

            {digitalTwinInstance &&
            waterMeterClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ? (
              <WaterMeterShow
                device={digitalTwinInstance}
                attributes={digitalTwinAttributes}
                categories={categories}
              ></WaterMeterShow>
            ) : (
              <></>
            )}

            {digitalTwinInstance &&
            (digitalTwinInstance.data.attributes.class == "WaterLeakCable" ||
              digitalTwinInstance.data.attributes.class == "Prosino") ? (
              <WaterLeakCableShow
                device={digitalTwinInstance}
                categories={categories}
              ></WaterLeakCableShow>
            ) : (
              <></>
            )}
            {digitalTwinInstance &&
            digitalTwinInstance.data.attributes.class == "PressureSensor" ? (
              <PressureSensorShow
                device={digitalTwinInstance}
                categories={categories}
              ></PressureSensorShow>
            ) : (
              <></>
            )}
            {digitalTwinInstance &&
            !waterMeterClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) &&
            !gatewayClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) &&
            !sensorClaesses.includes(
              digitalTwinInstance.data.attributes.class
            ) &&
            digitalTwinInstance.data.attributes.class != "WaterLeakCable" ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flexGrow: 2 }}>
                  {getCategoryKeys(categories)?.map((category) => (
                    <Accordion
                      expanded={expanded.includes(category)}
                      onChange={handleChangeTab(category)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {category}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {categories[category]?.map(
                          ({ attributes: { name, access, type } }) => (
                            <DeviceShowItem
                              instance={digitalTwinInstance}
                              name={name}
                              access={access}
                              type={type}
                            />
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
                <Box sx={{ flexGrow: 1, maxHeight: 300 }}>
                  <DeviceMap
                    device={
                      digitalTwinInstance ? digitalTwinInstance.data : null
                    }
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DeviceCommandProvider>
              <DeviceCommandForm
                instance={digitalTwinInstance}
                categories={categories}
              />
            </DeviceCommandProvider>
          </TabPanel>
          {/*					<TabPanel value={value} index={2}></TabPanel> */}
          <TabPanel value={value} index={2}>
            {digitalTwinInstance &&
            waterMeterClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ? (
              <WaterMeterReadings
                device={digitalTwinInstance ? digitalTwinInstance.data : null}
              />
            ) : digitalTwinInstance &&
              (digitalTwinInstance.data.attributes.class == "WaterLeakCable" ||
                digitalTwinInstance.data.attributes.class == "Prosino") ? (
              <WaterLeakCableMessages
                device={digitalTwinInstance ? digitalTwinInstance.data : null}
              />
            ) : (
              <DeviceMessage
                device={digitalTwinInstance ? digitalTwinInstance.data : null}
              />
            )}
          </TabPanel>

          {digitalTwinInstance &&
          gatewayClasses.includes(digitalTwinInstance.data.attributes.class) ? (
            <TabPanel value={value} index={3}>
              <GatewayCoverage device={digitalTwinInstance}></GatewayCoverage>
            </TabPanel>
          ) : (
            <></>
          )}

          {/*					<TabPanel value={value} index={4}>
						{" "}
					</TabPanel>
					<TabPanel value={value} index={5}>
						{" "}
					</TabPanel> */}
          {digitalTwinInstance &&
          (waterMeterClasses.includes(
            digitalTwinInstance.data.attributes.class
          ) ||
            gatewayClasses.includes(
              digitalTwinInstance.data.attributes.class
            ) ||
            sensorClaesses.includes(
              digitalTwinInstance.data.attributes.class
            ) ||
            digitalTwinInstance.data.attributes.class != "WaterLeakCable") ? (
            <TabPanel value={value} index={4}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ flexGrow: 2 }}>
                  {getCategoryKeys(categories)?.map((category) => (
                    <Accordion
                      expanded={expanded.includes(category)}
                      onChange={handleChangeTab(category)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {category}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {categories[category]?.map(
                          ({ attributes: { name, access, type } }) => (
                            <DeviceShowItem
                              instance={digitalTwinInstance}
                              name={name}
                              access={access}
                              type={type}
                            />
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
                <Box sx={{ flexGrow: 1, maxHeight: 300 }}>
                  <DeviceMap
                    device={
                      digitalTwinInstance ? digitalTwinInstance.data : null
                    }
                  />
                </Box>
              </Box>
            </TabPanel>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ShowDevice;
